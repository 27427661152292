.why-to-invest {
  padding-top: 200px;
  padding-bottom: 200px;
  //background: url(../img/bg/why-to-invest.jpg) center no-repeat;
  //background-size: cover;

  .container {
    position: relative;
    z-index: 1;
  }

  .swc-title {
    color: white;
  }

  &__info {
    margin: auto;
    font-size: 0;
    text-align: center;
  }

  &__item {
    font-size: initial;
    display: inline-block;
    vertical-align: top;
    width: 33.33%;
    padding: 0 20px;
    text-align: center;

    &:first-child {
      padding-left: 0;
    }

    &:last-child {
      padding-right: 0;
    }

    &-img {
      //@include outw;
      width: 84px;
      height: 84px;
      margin: auto;
      margin-bottom: 16px;

      &:before {
        display: inline-block;
        content: '';
        height: 100%;
        vertical-align: middle;
        width: 0;
      }

      svg {
        //@include outr;
        display: inline-block;
        vertical-align: middle;
      }
    }

    &-title {
      font: 24px $rb;
      font-weight: bold;
      margin-bottom: 16px;
      color: white;
    }

    &-text {
      font: 18px/1.56 $rr;
      color: white;
    }
  }
}

@media screen and (max-width: $tab-max) {

  .why-to-invest {
    &__item {
      width: 100%;
      max-width: 600px;
      padding: 0;
      margin: auto;
      margin-bottom: 40px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

}

@media screen and (max-width: $tab-min) {

  .why-to-invest {
    padding-top: 60px;
    padding-bottom: 60px;
  }

}