.countdown {
  font: 56px/60px $rl;
  font: 3vw/1.3 $rl;
  margin-bottom: 5px;

  b {
    font: 56px/60px $rm;
    font: 3vw/1.3 $rm;
  }

  span {
    margin: 0 15px;
  }
}

@media screen and (max-width: $tab-max) {
  .countdown {
    font: 56px/60px $rl;

    b {
      font: 56px/60px $rm;
    }

    span {
      margin: 0 15px;
    }
  }
}

@media screen and (max-width: $tab-min) {
  .countdown {
    font: 44px/48px $rl;

    b {
      font: 44px/48px $rm;
    }

    span {
      margin: 0 15px;
    }
  }
}