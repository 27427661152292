.crowdinvesting {
  padding-top: 60px;
  padding-bottom: 40px;
  background: url('../img/bg/train.jpg') bottom center no-repeat;
  background-size: cover;
  color: black;
  position: relative;
  overflow: hidden;

  &__wrap {
    max-width: 1100px;
    margin: auto;
    padding: 0 20px;
    position: relative;
    z-index: 1;
  }

  &__title {
    font: 40px/1.2 $rb;
    font-weight: bold;
    margin-bottom: 100px;
    text-align: center;
  }

  &__list-wrap {

  }

  &__list {
    //height: 450px;
    @include flex(row, space-between, flex-start);
    flex-wrap: wrap;

    &-item {
      margin-bottom: 96px;
      width: 50%;
      max-width: 400px;

      &:nth-child(odd) {
        padding-right: 15px;
      }
      &:nth-child(even) {
        padding-left: 15px;
      }

      &:last-child {
        //margin-bottom: 0;
      }

      &-title {
        font: 24px $rb;
        font-weight: bold;
        margin-bottom: 10px;
        color: $main-blue;
      }

      &-text {
        font: 16px/1.5 $rr;
      }

    }

  }
}

@media screen and (max-width: $tab-max) {}

@media screen and (max-width: $tab-min) {

  .crowdinvesting {
    padding-top: 40px;
    padding-bottom: 40px;

    &__title {
      font: 30px/1.2 $rb;
      font-weight: bold;
      margin-bottom: 60px;
    }

    &__list {

      &-item {
        width: 100%;
        padding-right: 0;
        margin-bottom: 50px;

        &:nth-child(odd) {
          padding-right: 0;
        }
        &:nth-child(even) {
          padding-left: 0;
        }
      }
    }
  }

}
