.video-banner {
  //@include outr;
  //position: fixed;
  height: 100vh;
  //width: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  text-align: center;
  position: relative;
  overflow: hidden;

  &-wrap {
    //height: calc(100vh + 300px);
    //padding: 0;
  }

  &__info {
    height: 100vh;
    border-top: 70px solid #fafbfc;
    border-right: 140px solid #fafbfc;
    border-left: 140px solid #fafbfc;
    border-bottom: 70px solid #fafbfc;
    position: relative;
    white-space: nowrap;
    padding: 30px;
    background-color: rgba(0, 48, 135, .3);
    z-index: 2;
    pointer-events: none;

    &:after {
      display: inline-block;
      vertical-align: middle;
      content: '';
      height: 100%;
      width: 0;
    }

    &-title {
      white-space: normal;
      //max-width: 840px;
      margin: auto;
      color: white;
      display: inline-block;
      vertical-align: middle;
      font: 64px/1.33 $rb;
      font-weight: bold;
      font-size: 5vw;

      span {
        font: 64px/1.33 $rb;
        font-weight: bold;
        font-size: 5vw;
      }
    }
  }

  &_technology {
    //background-image: url('../img/bg/1.jpg');
    //background-size: cover;
    //background-position: center;
  }

  @-webkit-keyframes animate-banner-title {
    0% {
      opacity: 0;
      transform: translateY(50px)
    }

    100% {
      opacity: 1;
      transform: translateY(0px)
    }
  }

  video {
    position: absolute;
    top: -100%;
    bottom: -100%;
    left: -100%;
    right: -100%;
    margin: auto;
    min-height: 100%;
    min-width: 100%;
  }

  &-title {
    padding-top: 120px;
    padding-bottom: 50px;
    background-color: #fafbfc;
  }

  &_years {
    //@include outw;
    @include flex(row, space-around, center);
    flex-grow: 3;
  }

  &_tabs {
    width: 80%;
    background-color: rgba(1,33,105,.7);
    position: absolute;
    bottom: 0;
    @include flex(row, space-around, center);
    flex-wrap: wrap;
    text-align: center;
    left: 10%;
    padding: 36px 10px;
    z-index: 1;
    opacity: 1;
    transition: opacity 0.3s;

    &.no_active {
      opacity: 0!important;
      transition: opacity 0.3s;
    }

    &-title {
      //@include outw;
      font-family: $rb;
      font-weight: bold;
      font-size: 56px;
      line-height: 1.29;
      color: #fff;
      flex-grow: 1;
      border-bottom: 8px solid transparent;
    }
    &-tab {
      font-family: $rl;
      font-size: 56px;
      line-height: 1.29;
      color: #fff;
      cursor: pointer;
      border-bottom: 8px solid transparent;

      &.active {
        font-family: $rb;
        font-weight: bold;
        border-bottom: 8px solid #005bff;
      }
    }
  }

  &_img {
    position: relative;
    background-size: cover;
    background-position: center;

    &.clean {
      background-image: none;
    }
  }
  &_play {
    position: absolute;
    width: 70px;
    height: 70px;
    left: 50%;
    top: 50%;
    margin: -35px 0 0 -35px;
    cursor: pointer;
    z-index: 3;
    opacity: 1;
    transition: opacity 0.3s;

    &.no_active {
      opacity: 0!important;
      transition: opacity 0.3s;
    }
  }
}
.js-video-banner{
  &_years{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    flex-grow: 3;
    opacity: 0;
    transition-property: opacity;
    transition-duration: 1s;
    transition-delay: 50ms;
    &__next, &__prev{
      font-size: 50px;
      color: #fff;
      transition-property: color;
      transition-duration: 50ms;
      &:hover{
        color: #005bff;
        cursor: pointer;
        transition-property: color;
        transition-duration: 1s;
      }
    }
  }
}

@media screen and (max-width: $tab-max) {

  .video-banner {

    &__info {
      border-top: 70px solid white;
      border-right: 70px solid white;
      border-left: 70px solid white;
      border-bottom: 70px solid white;
    }

  }

}

@media screen and (max-width: $tab-min) {

  .video-banner {

    &__info {
      border-top: 40px solid white;
      border-right: 40px solid white;
      border-left: 40px solid white;
      border-bottom: 40px solid white;
    }

    &_tabs {
      left: 5%;
      width: 90%;
      padding: 20px 10px;

      &-title {
        padding-top: 0;
        font: 40px $rb;
        font-weight: bold;
      }
      &-tab {
        font: 40px $rl;
      }
    }
    video {
      width: 100%;
      height: 100%;
    }
  }
}






































