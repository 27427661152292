.how-to-invest {
  padding-top: 120px;
  padding-bottom: 160px;
  counter-reset: invest-steps;
  background: #fafbfc;

  &__items {
    margin-bottom: 65px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__item {
    position: relative;
    @include flex;
    min-height: 400px;

    &:nth-child(even) {
      @include flex(row-reverse, space-between, center);

      .how-to-invest__item-text {
        padding-left: 0;
        padding-right: 110px;
      }

      .how-to-invest__item-img {
        //text-align: right;
      }
    }

    &:before {
      counter-increment: invest-steps;
      content: counter(invest-steps);
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      margin: auto;
      display: inline-block;
      text-align: center;
      font: 420px/.9 $rb;
      font-weight: bold;
      color: #eee;
      z-index: 0;
      //outline: 1px solid;
      width: 240px;
      height: 370px;
    }

    &-img {
      //@include outg;
      width: 43%;
      position: relative;
      z-index: 1;
      text-align: center;

      &_ps {
        img {
          width: 100%;
          max-width: 501px;
        }
      }
    }

    &-text {
      //@include outg;
      width: 57%;
      font: 24px/1.67 $rr;
      position: relative;
      padding-left: 110px;
      //max-width: 510px;
    }
  }

  &__btn {
    text-align: center;
  }

}

@media screen and (max-width: $tab-max) {}

@media screen and (max-width: $tab-min) {

  .how-to-invest {
    padding-top: 60px;
    padding-bottom: 60px;

    &__item {
      min-height: 240px;

      &:before {
        font: 240px/.9 $rb;
        font-weight: bold;
        height: 230px;
      }

      &-img {
        display: none;
      }

      &-text {
        width: 100%;
        padding: 0;
        text-align: center;
      }

      &:nth-child(even) {

        .how-to-invest__item-text {
          padding: 0;
        }
      }
    }

  }

}





































