.shows {
  padding-top: 115px;
  padding-bottom: 115px;
  background-color: #fff;

  &_grey {
    background-color: #fafbfc;
  }

  .container {
    overflow: hidden;
  }

  &__top {
    @include flex;
  }

  &__title {
    font: 40px/1.2 $rb;
    font-weight: bold;
    margin-bottom: 30px;
  }

  &__nav {
    height: 50px;
    @include flex(row, flex-end, center);
    margin-bottom: 30px;
  }

  &__galleries {
    font-size: 0;
    white-space: nowrap;
    margin: 0 -20px;
    position: relative;

    &:after {
      position: absolute;
      display: block;
      content: '';
      width: 80px;
      height: 100%;
      top: 0;
      right: 0;
      background-image: linear-gradient(to right,rgba(255,255,255,0),rgba(250,250,250,1));
      pointer-events: none;
    }
  }

  &__gallery {
    white-space: normal;
    font-size: initial;
    //max-width: 290px;
    //height: 485px;
    width: 25%;
    transition: all .2s ease;
    display: inline-block;
    vertical-align: top;
    //margin-right: 40px;
    cursor: pointer;
    overflow: hidden;
    padding: 0 20px;

    &-img {
      position: relative;
      display: inline-block;
      width: 100%;

      &:before {
        position: absolute;
        display: block;
        content: '';
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        opacity: 0;
        background: #003087 url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI1MSIgaGVpZ2h0PSI1MyIgdmlld0JveD0iMCAwIDUxIDUzIj4gICAgPGcgZmlsbD0iI0ZGRiIgZmlsbC1ydWxlPSJub256ZXJvIj4gICAgICAgIDxwYXRoIGQ9Ik0yNy45ODMgMjBoLTZ2LTZhMSAxIDAgMCAwLTIgMHY2aC02YTEgMSAwIDAgMCAwIDJoNnY2YTEgMSAwIDAgMCAyIDB2LTZoNmExIDEgMCAwIDAgMC0yeiIvPiAgICAgICAgPHBhdGggZD0iTTUwLjcwNCA1MS4yNzNMMzUuODQ1IDM1LjgyYzMuNzktMy44MDEgNi4xMzgtOS4wNDEgNi4xMzgtMTQuODIgMC0xMS41OC05LjQyLTIxLTIxLTIxcy0yMSA5LjQyLTIxIDIxIDkuNDIgMjEgMjEgMjFjNS4wODMgMCA5Ljc0OC0xLjgxNyAxMy4zODQtNC44MzJsMTQuODk1IDE1LjQ5MWEuOTk4Ljk5OCAwIDAgMCAxLjQxNC4wMjggMSAxIDAgMCAwIC4wMjgtMS40MTR6TTEuOTgzIDIxYzAtMTAuNDc3IDguNTIzLTE5IDE5LTE5czE5IDguNTIzIDE5IDE5LTguNTIzIDE5LTE5IDE5LTE5LTguNTIzLTE5LTE5eiIvPiAgICA8L2c+PC9zdmc+) no-repeat center;
        transition: all .2s ease;
      }
    }

    &:hover {
      .shows__gallery-img {
        &:before {
          opacity: 0.7;
          box-shadow: 0 0 10px 0 rgba(0,0,0,.5);
        }
      }
    }

    figure {
      &:not(:first-child) {
        display: none;
      }
    }

    span {
      position: relative;
    }

    img {
      width: 100%;
    }

    &-name {
      text-align: center;
      font: 16px/1.5 $rr;
      padding: 0 10px;
      display: block;
      margin-top: 12px;

      b {
        display: block;
      }
    }
    &-title {
      opacity: 0.9;
      font-family: $rb;
      font-weight: bold;
      font-size: 24px;
      line-height: 1.33;
      color: #fff;

      &_sub {
        opacity: 0.9;
        font-family: $rl;
        font-size: 18px;
        line-height: 1.33;
        color: #fff;
      }
    }
  }

  .slick-list {
    padding: 0 60px 0 0;
  }
}

@media screen and (max-width: $tab-max) {

  .shows {

    &__title {
      font: 28px/1.2 $rb;
      font-weight: bold;
    }

  }

}

@media screen and (max-width: 540px) {

  .shows {

    &__title {
      font: 22px/1.2 $rb;
      font-weight: bold;
    }

  }

}

@media screen and (max-width: $tab-min) {

  .shows {
    padding-top: 60px;
    padding-bottom: 60px;
  }

}







































