.mintrans {
  padding-top: 120px;
  padding-bottom: 120px;
  background-color: white;
  text-align: center;

  &__title {
    padding-top: 130px;
    font: 40px/1.2 $rb;
    font-weight: bold;
    background: url(../img/icons/emblem.jpg) center top no-repeat;
    max-width: 840px;
    margin: 0 auto 40px;
  }

  &__text {
    font: 16px/1.5 $rr;
    max-width: 840px;
    margin: 0 auto 40px;
  }

  &__link {
    margin-bottom: 20px;

    &:last-child {
      margin-bottom: 0;
    }

    a {
      font: 18px/1.5 $rr;
      text-decoration: underline;
    }
  }
}

@media screen and (max-width: $tab-max) {}

@media screen and (max-width: $tab-min) {

  .mintrans {
    padding-top: 60px;
    padding-bottom: 60px;
  }

}