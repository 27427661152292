.faq {
  padding-top: 100px;
  padding-bottom: 100px;
  background-color: #fff;

  &__title {
    font: 40px/1.2 $rbl;
    margin-bottom: 75px;
  }

  &__li {
    padding: 24px 100px 24px 110px;
    background-color: #f4f4f4;
    margin-bottom: 10px;

    &.active {
      background-color: #f1f6ff;
    }
  }

  &__q {
    font: 24px $rr;
    display: inline;
    cursor: pointer;
    user-select: none;
    color: $main-blue;
    transition: all .15s ease;
    border-bottom: 2px dotted transparent;

    &:hover {
      border-bottom: 2px dotted;
    }
  }

  &__a {
    display: none;
    font: 24px/1.67 $rl;
    margin-top: 24px;

    &-p {
      font: 24px/1.67 $rl;
    }
    &-ul {

    }
    &-li {
      font: 24px/1.67 $rl;
      &:before {
        top: 2px;
        content: "\2022";
        margin-right: 7px;
      }
    }

    &-double {
      @include flex(row, flex-start, flex-start);
    }

    &-text {
      width: 60%;
    }
    &-scheme {
      position: relative;
      width: 40%;
      text-align: left;
      padding-left: 20px;


    }
    &-decryption {
      display: block;
      margin-bottom: 8px;
      font-family: $rr;
      font-size: 14px;
      line-height: 1.57;
      color: #000;
    }
    &-tooltip {
      font-family: $rl;
      color: #005bff;
      cursor: pointer;

      &-ol {
        margin-top: 8px;
      }
      &-li {
        padding-left: 10px;
        position: relative;

        &:before {
          position: absolute;
          left: 0;
          top: 2px;
          color: #003087;
          content: "\2022";
        }
      }
    }
  }
}

@media screen and (max-width: $tab-max) {
  .faq {
    &__li {
      &:nth-child(1) {
        .faq {
          &__a {
            &-double {
              padding-bottom: 40px;
            }
            &-scheme {
              max-width: 353px;
            }
          }
        }
      }
      &:nth-child(2) {
        .faq {
          &__a {
            &-double {
              padding-bottom: 15px;
            }
            &-scheme {
              max-width: 319px;
            }
          }
        }
      }
      &:nth-child(3) {
        .faq {
          &__a {
            &-double {
              padding-bottom: 22px;
            }
            &-scheme {
              max-width: 409px;
            }
          }
        }
      }
      &:nth-child(4) {
        .faq {
          &__a {
            &-double {
              padding-bottom: 35px;
            }
            &-scheme {
              max-width: 386px;
            }
          }
        }
      }
      &:nth-child(5) {
        .faq {
          &__a {
            &-double {
              //padding-bottom: 35px;
            }
            &-scheme {
              max-width: 355px;
              margin-top: -16px;
            }
          }
        }
      }
    }
    &__a {

      &-double {
        flex-direction: column;
      }
      &-text {
        width: 100%;
        margin-bottom: 40px;
      }
      &-scheme {
        width: 100%;
        padding-left: 0;
      }
    }
  }
}

@media screen and (max-width: $tab-min) {

  .faq {
    &__li {
      padding: 24px 20px 24px 30px;

      &:nth-child(1) {
         .faq__a {
           &-scheme {
             margin-left: -4%;
           }
         }
      }
      &:nth-child(2) {
        .faq__a {
          &-scheme {
            margin-left: -5%;
          }
        }
      }
      &:nth-child(5) {
        .faq__a {
          &-scheme {
            margin-left: -15%;
          }
        }
      }
    }

    &__q {
      font: 20px $rr;
    }

    &__a {
      font: 20px/1.67 $rl;

      &-double {
        flex-direction: column;
      }
      &-text {
        width: 100%;
        margin-bottom: 40px;
      }
      &-scheme {
        width: 100%;
      }
    }
  }
}





































