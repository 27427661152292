.leadership {
  padding-top: 60px;
  padding-bottom: 60px;
  background: linear-gradient(175deg, #FFFFFF 0, #fafbfc 100%);

  &__tab-names {
    @include flex(row, space-around, center);
    margin-bottom: 40px;
  }

  &__tab-name {
    font: 20px/1.35 $rm;
    color: #9b9b9b;
    text-transform: uppercase;
    cursor: pointer;
    user-select: none;
    transition: all .2s ease;

    &:hover, &.active {
      color: #2164f4;
      text-decoration: underline;
    }
  }

  &__tab-content {

  }

  &__tab {
    &:not(:first-child) {
      display: none;
    }

    &-line {
      position: relative;
      background-color: #e7e7e7;
      font: 16px/1.88 $rm;
      display: block;
      margin-bottom: 20px;
      white-space: nowrap;
      width: 10%;
      height: 50px;

      &:last-child {
        margin-bottom: 0;
      }

      &_sw {
        background-color: #1769ff;
        color: white;
      }

      &-count {
        position: absolute;
        top: 0;
        right: -55px;
        height: 100%;
        padding: 10px 0 10px 14px;
        font: 16px/1.88 $rm;
        color: black;
        //display: none;
      }
      &-name {
        line-height: 50px;
        width: 330px;
        font-family: $rr;
        font-size: 16px;
        color: #000;

        &_sw {
          font-family: $rm;
          color: #005bff;
        }
      }
      &-item {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: inherit;
        margin-bottom: 28px;
      }

      &-cont {
        $fixedColSize: 390px;
        width: calc(100% - #{$fixedColSize});
      }
    }

    &-note {
      font: 16px/1.75 $rr;
      color: #939393;
      max-width: 840px;
      margin: auto;
      padding-top: 20px;
    }

  }

}

@media screen and (max-width: $tab-max) {
  .leadership {

    &__tab {
      &-names {
        //@include flex(column, space-around, flex-start);

      }
      &-name {
        //margin-bottom: 15px;
        font-size: 2vw;
      }
      &-content {
        max-width: 1000px;
        text-align: left;
        margin: 0 auto 40px;
      }
      &-line {

        height: 50px;

        &-item {
          flex-direction: column;
        }
        &-cont {
          $fixedlSize: 50px;

          width: calc(100% - #{$fixedlSize});
        }
      }

    }

  }
}

@media screen and (max-width: $tab-min) {
  .leadership {

    &__tab-names {
      @include flex(column, space-around, flex-start);
    }

    &__tab-name {
      margin-bottom: 15px;
      font: 20px/1.35 $rm;

      &:last-child {
        margin-bottom: 0;
      }
    }

    &__tab-line {
      padding: 0 14px;

      &-count {
        padding: 0 0 0 14px;
      }
    }

  }
}