.about-top {
  padding-top: 64px;
  padding-bottom: 233px;
  //background: url("../img/bg/about-top.jpg") no-repeat center;
  //background-size: cover;
  color: white;

  .container {
    position: relative;
    z-index: 1;
  }

  &__title {
    font: 24px/1.5 $rl;
    margin-bottom: 185px;
    text-align: center;
  }

  &__text {
    max-width: 1060px;
    margin: auto;
    text-align: center;
  }

  p {
    font: 36px/1.56 $rl;
    margin-bottom: 64px;
    display: block;
  }
}

@media screen and (max-width: $tab-max) {}

@media screen and (max-width: $tab-min) {

  .about-top {
    padding-top: 60px;
    padding-bottom: 60px;

    &__title {
      margin-bottom: 100px;
    }

    p {
      font: 28px/1.56 $rl;
      margin-bottom: 44px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

}












































