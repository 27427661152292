.reviews {
  padding-top: 120px;
  background-color: #fff;

  &_grey {
    background-color: #fafbfc;
  }

  &__slider {
    margin: 0 -20px;
    font-size: 0;
    padding-bottom: 70px;
    text-align: center;
  }

  &__slide {
    font-size: initial;
    //@include outr;
    width: 33.33%;
    padding: 0 20px;
    display: inline-block;
    vertical-align: top;
    max-width: 440px;
    text-align: left;

    &_full {
      padding: 80px 110px;
      vertical-align: middle;
      width: auto;
      max-width: 840px;

      .reviews__slide-top {
        margin-bottom: 50px;
      }

      .reviews__slide-text {
        font: 18px/2 $rr;
        max-height: none;

        p {
          margin-bottom: 15px;

          &:last-child {
            margin-bottom: 0;
          }
        }
      }
    }

    &-top {
      @include flex;
      margin-bottom: 20px;
    }

    &-img {
      padding-right: 20px;
      flex-shrink: 0;
      width: 140px;

      img {
        border-radius: 50%;
      }
    }

    &-info {
      flex-grow: 2;
    }

    &-flag {
      width: 20px;
      height: 15px;
      vertical-align: bottom;
      border: 1px solid #ededed;
      margin-right: 5px;
    }

    &-name {
      font: 24px/1.17 $rm;
      margin-bottom: 4px;
    }

    &-age {
      font: 12px $rr;
      color: #4a4a4a;
      margin-bottom: 10px;
    }

    &-country {
      font: 12px $rr;
      color: #4a4a4a;
    }

    &-text {
      font: 16px/28px $rr;
      max-height: 112px;
      overflow: hidden;
    }

    &-more {
      font: 18px $rm;
      color: $main-blue;
      margin-top: 20px;
      display: inline-block;
      cursor: pointer;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  &__nav {
    @include flex(row, flex-end, center);
    height: 50px;
    margin-bottom: 30px;
  }
}

@media screen and (max-width: $tab-max) {}

@media screen and (max-width: $tab-min) {

  .reviews {
    padding-top: 60px;

    &__slide {
      &_full {
        padding: 80px 40px;
      }
    }
  }

}