.leaders {
  padding-top: 100px;
  padding-bottom: 150px;
  background-color: #fafbfc;
  display: none;

  &__top {
    @include flex;
    margin-bottom: 84px;
  }

  &__title {
    font: 40px/1.4 $rb;
    font-weight: bold;
  }

  &__slider {
    margin: 0 -20px;
    font-size: 0;
    white-space: nowrap;
    overflow: hidden;
  }

  &__slide {
    //@include outr;
    display: inline-block;
    font-size: initial;
    width: 25%;
    text-align: center;
    padding: 0 20px;
    white-space: normal;

    &-img {
      margin-bottom: 25px;

      img {
        border-radius: 50%;
        margin: auto;
      }
    }

    &-name {
      font: 24px $rr;
      margin-bottom: 8px;
    }

    &-text {
      font: 16px/1.5 $rr;
    }
  }

}

@media screen and (max-width: $tab-max) {}

@media screen and (max-width: $tab-min) {
  .leaders {
    padding-top: 60px;
    padding-bottom: 60px;

    &__slider {
      padding-bottom: 40px;
    }

    &__title {
      font: 30px/1.4 $rb;
      font-weight: bold;
    }
  }

}