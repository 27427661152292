.track-structure {
  padding-top: 120px;
  padding-bottom: 60px;
  //background: url('../img/structure/structure.png') center no-repeat;
  //background-size: cover;
  //min-height: 674px;
  background-color: white;

  &__title {
    font: 40px/1.4 $rb;
    font-weight: bold;
    margin-bottom: 20px;
    max-width: 620px;
  }

  &__text {
    font: 18px/1.83 $rr;
    max-width: 620px;
  }

  .container {
    position: relative;
  }

  &__info {
    position: absolute;
    top: -50px;
    left: 10px;
    z-index: 1;
  }

  &__imgs {
    position: relative;
    
    &-slider {
      img {
        width: 100%;
        
        &:not(:last-child) {
          display: none;
        }
      }
    }
  }

  &__list {
    &-item {
      position: absolute;
      width: 40px;
      height: 40px;
      transform: translate(-50%, -50%);

      &:before {
        position: absolute;
        content: '';
        display: inline-block;
        width: 40px;
        height: 40px;
        border-radius: 50%;
        border: 10px solid white;
        background-color: $main-blue;
        box-shadow: 0 0 15px 0 rgba(0, 0, 0, .2);
        cursor: pointer;
        transition: all .2s ease;
        box-sizing: border-box;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        margin: auto;
      }

      &.active {
        &:before {
          border: 10px solid $main-blue;
          background-color: white;
        }
      }

      &_track {
        top: 62%;
        left: 25%;
      }
      &_stock {
        top: 41%;
        left: 52%;
      }
      &_infrastructure {
        top: 17%;
        right: 21%;
      }
    }
  }
}

@media screen and (max-width: $tab-max) {
  .track-structure {
    padding-top: 60px;

    &__info {
      position: static;
    }
  }
}

@media screen and (max-width: $tab-min) {

  .track-structure {

    &__info {
      margin-bottom: 60px;
    }

    &__title {
      font: 30px/1.4 $rb;
      font-weight: bold;
    }

    &__list {
      &-item {
        width: 25px;
        height: 25px;

        &:before {
          width: 25px;
          height: 25px;
          border: 6px solid white;
        }

        &.active {
          &:before {
            border: 6px solid $main-blue;
          }
        }
      }
    }

  }

}





























