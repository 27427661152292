.header {

	&__nav {
		background-color: white;
		padding: 22px 0;
		//box-shadow: 0 0px 10px rgba(0, 0, 0, .5);

		&-links {
			width: 100%;
			@include flex;
			flex-wrap: nowrap;
			
			&_mobile {
				display: none;
			}
		}

		&-link {
			font: 18px/20px $rr;
			white-space: nowrap;
			padding: 11px 8px 8px;
			text-decoration: none!important;
			border-bottom: 2px solid transparent;
			font-weight: 600;

			&:hover {
				border-bottom: 2px solid $main-blue-light;
			}

			&.active {
				color: $main-blue-light;
				border-bottom: 2px solid $main-blue-light;
			}

			&-span {
				border-bottom: 2px dotted;
			}
		}

	}

}

@media screen and (max-width: $tab-max) {
	.header {
		
		&__nav {

			&-link {
				font-size: 16px;
			}

		}
	}

}

@media screen and (max-width: $tab-min) {
	.header {
		
		&__nav {
			background-color: $main-blue;
			display: none;
			padding-bottom: 25px;
			
			&-links {
				@include flex(column);
				margin-bottom: 25px;
				
				&:last-child {
					margin-bottom: 0;
				}
				
				&_mobile {
					display: flex;
				}
			}

			&-link {
				color: white;
				font-size: 18px;
				font-family: $rr;
				padding: 15px 0;
				width: 100%;
    			text-align: center;
				border-bottom: none;

				&.active {
					color: white;
					border-bottom: none;
					background-color: #2164f4;
				}

				&:hover {
					border-bottom: none;
				}
			}

		}
	}

}
