.dynamic {
  padding-top: 140px;
  padding-bottom: 60px;
  text-align: center;
  background-color: #fafbfc;

  &__nav-tabs {
    //@include outr;
    font-size: 0;
    display: inline-block;
    margin: auto;
    margin-bottom: 56px;
  }

  &__nav-tab {
    font-size: initial;
    display: inline-block;
    font: 14px/1.43 $rm;
    padding: 16px 0;
    width: 400px;
    text-align: center;
    margin: 0 20px;
    text-transform: uppercase;
    color: $main-blue;
    border-bottom: 2px solid;
    cursor: pointer;
    transition: all .2s ease;
    user-select: none;

    &:hover, &.active {
      color: $main-blue-light;
    }

    &:last-child {
      //margin-right: 0;
    }
  }

  &__tab {
    text-align: center;

    img {
      max-width: 100%;
    }
  }

  &__items {
    //@include outg;
    @include flex;
    margin: 0 -20px;
    //margin-bottom: 120px;
  }

  &__item {
    //@include outr;
    padding: 0 20px;
    text-align: center;
    width: 33.33%;

    &-top {
      background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMTEiIGhlaWdodD0iMTI2IiB2aWV3Qm94PSIwIDAgMTExIDEyNiI+ICAgIDxwYXRoIGZpbGw9IiMwMDVCRkYiIGZpbGwtcnVsZT0iZXZlbm9kZCIgZD0iTTAgMTI2di0xOC4zOWMwLTEuNTMyLjM4My0yLjkyMSAxLjE0OS00LjE2Ni43NjYtMS4yNDYgMi4xMDYtMi4zNDcgNC4wMi0zLjMwNWw2NC40NzUtMzIuNjEzYTU3LjEwOCA1Ny4xMDggMCAwIDEgNi4wMzEtMi41ODYgNDYuMjc5IDQ2LjI3OSAwIDAgMSA2Ljc1LTEuODY4IDc1LjU5OSA3NS41OTkgMCAwIDEtNi43NS0xLjk0IDM3Ljc4IDM3Ljc4IDAgMCAxLTYuMDMtMi42NThMNS4xNjggMjUuNzE3Yy0xLjkxNC0uOTU4LTMuMjU0LTIuMDM1LTQuMDItMy4yMzJDLjM4MyAyMS4yODcgMCAxOS44NzUgMCAxOC4yNDZWMGwxMTEgNTcuNjEydjEwLjYzMkwwIDEyNnoiIG9wYWNpdHk9Ii4wOCIvPjwvc3ZnPg==);
      background-repeat: no-repeat;
      background-size: 111px 126px;
      background-position: top center;
      padding-top: 60px;
      font: 36px $rr;
      color: $main-blue-light;
      //margin-top: 16px;
      margin-bottom: 16px;
    }

    &-text {
      font: 20px/1.4 $rr;
    }
  }

}

@media screen and (max-width: $tab-max) {}

@media screen and (max-width: $tab-min) {

  .dynamic {
    padding-top: 60px;
    padding-bottom: 60px;

    &__items {
      flex-wrap: wrap;
      //margin-bottom: 40px;
    }

    &__item {
      width: 100%;
      margin: 0 auto 25px;
      align-self: flex-start;

      &:last-child {
        margin-bottom: 0;
      }

      &-top {
        font: 28px $rr;
      }

      &-text {
        font: 18px/1.4 $rr;
      }
    }

    &__nav-tab {
      width: auto;
    }
  }

}














































