.scheme {
  padding-top: 100px;

  svg text {
    font-family: $rb;
    font-weight: bold;
  }

  &-center {
    text-align: center;
  }
  &-object {
    width: 100%;
  }
  &-one {
    position: relative;
    display: inline-block;
    margin: 72px auto 0;
    text-align: left;
    width: 100%;
    max-width: 1044px;

    &-icon {
      display: inline-block;
      position: absolute;
      width: 20px;
      height: 20px;
      cursor: pointer;
      transition: all .3s ease-out;

      &_dark {
        fill: #fff;
        color: #003087;
      }
      &_white {
        fill: #003087;
        color: rgba(0, 48, 135, .05);
      }
      &_transparent {
        fill: #fff;
        color: rgba(255, 255, 255, .05);
      }

      &_1 {
        top: 43%;
        left: 20%;
      }
      &_2 {
        top: 43%;
        left: 47.8%;
      }
      &_3 {
        top: 89%;
        left: 62.7%
      }
      &_4 {
        top: 20.8%;
        left: 93.4%;
      }
      &_5 {
        top: 20.8%;
        left: 58.1%;
      }
      &_6 {
        top: 60.8%;
        left: 8.5%;
      }
      &_7 {
        top: 66.5%;
        left: 35%;
      }
      &_8 {
        top: 59.2%;
        left: 69.4%;
      }
      &_9 {
        top: 17.2%;
        left: 82.9%;
      }
      &_10 {
        top: 59.2%;
        left: 97.3%;
      }

      &:hover {
        fill: #fff;
        color: #005bff;

        circle {
          opacity: 1;
        }
      }
    }
    &-name {
      position: absolute;
      width: 195px;
      font-family: $rr;
      font-size: 16px;
      line-height: 1.13;
      text-align: center;
      color: #003087;

      &_1 {
        top: 73.7%;
        left: .4%;
      }
      &_2 {
        top: 79.4%;
        left: 26.5%;
      }
      &_3 {
        top: 70.1%;
        left: 53.8%;
      }
      &_4 {
        top: 69.9%;
        left: 81.6%;
      }
      &_5 {
        top: 28.1%;
        left: 67.4%;
      }
    }
  }
  &-two {

    svg text {
      font-family: $rb;
      font-weight: bold;
    }

    &-name {
      position: absolute;
      width: 180px;
      font-family: $rr;
      font-size: 14px;
      line-height: 1.13;
      text-align: center;
      color: #003087;

      &_1 {
        top: 98%;
        left: 2.4%;
      }
      &_2 {
        top: 98%;
        left: 60.6%;
      }
      &_3 {
        top: 37.6%;
        left: 30.5%;
      }
      &_4 {
        top: 86%;
        left: 4%;
      }
      &_5 {
        top: 98%;
        left: 60.6%;
      }
      &_6 {
        top: 86%;
        left: 10%;
      }
      &_7 {
        top: 24%;
        left: 77%;
        width: 120px;
      }
      &_8 {
        top: 96%;
        left: 77%;
        width: 120px;
      }
      &_9 {
        top: 95%;
        left: 3%;
      }
      &_10 {
        top: 73%;
        left: 60%;
      }
      &_11 {
        top: 84%;
        left: 4.5%;
      }
      &_12 {
        top: 76%;
        left: 59.4%;
      }
    }
  }
  &-tooltip {

    &__h {
      height: 100px;
      @include flex(row, center, center);
      padding: 0 30px;
      background-image: linear-gradient(to right, #003087, #12449e 31%, #12449e 53%, #0b3d95 71%, #003087);

      &-text {
        font-family: $rb;
        font-weight: bold;
        font-size: 20px;
        line-height: 1.2;
        text-align: center;
        color: #fff;
      }
    }
    &__txt {
      margin: 25px 32px;
    }
    &__close {
      position: absolute;
      width: 40px;
      height: 40px;
      top: 0;
      right: 0;
      border-top-right-radius: 6px;
      background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA4AAAAOCAYAAAAfSC3RAAAAAXNSR0IArs4c6QAAAMtJREFUKBWdksEGAlEUhkc01aakpGS0TET0VNG+B+oJokW0axExRJuIpE2S9Ay37x9dmquJOz+fe+45/z/GzAkCZIyZQkv1P+EpwgxChSYgHaGTFWQWwgKkuYJNOOiGTtB1w/RKsATpBePEQ9GAPUhniGyYugwrkJ4wsjMbrtOMNUUX6EEF1iA9YJgK2QuDGuxAusImqYy5cw6s7+eJoQrbT0DHDfquueA2ct15sv+rEvL/OIT8fwehfAtA8Hvl2lkfB1965WSk6b3kb04jfzSb9YjuAAAAAElFTkSuQmCC);
      background-repeat: no-repeat;
      background-position: center;
      cursor: pointer;

      &_no-header {
        background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA4AAAAOCAMAAAAolt3jAAAAjVBMVEUAAAAAM5kAVaoASZIAQJ8AOY4AN5IAM4gAPIcANpQAM4wAOosANYoAM48AOY4ANY0AMYwANIkAMokANIsAMYgAM4oAMYkAMogAMogAMYcAMYgAMIcAMYcAMYgAMYgAMYgAMIcAMYgAMIgAMYcAMYgAMIcAMYgAMIgAMYcAMYgAMYgAMIgAMYgAMIgAMIeeGK25AAAALnRSTlMABQYHCAkODxETFBYYGRsdHycpLC8yaGt2orCzt7rBxcjLztHW2dze4OXn6evtyzNqwwAAAGVJREFUeAFtj0cOhUAMxfzn99577xR49z8eGpgFkfDOUpQ4sB8TaB0cO32mlbmTjoze+s68tc/KtzB86TeHzkXZBmDw1H/RvSpdU9J/KLopWRHo3aV4SbPaYbvKHrIZPnJSi7QvFA0QDFJvVzQwAAAAAElFTkSuQmCC);
      }
    }
    &__h5 {
      font-family: $rm;
      font-size: 14px;
      line-height: 1.29;
      color: #000;
      padding: 0;
      margin: 0 0 16px;
    }
    &__p {
      font-family: $rr;
      font-size: 14px;
      line-height: 1.29;
      color: #000;
      margin: 0 0 16px;
    }
    &__em {
      font-family: $rr;
      font-size: 14px;
      font-style: normal;
      line-height: 1.29;
      color: #7e7e7e;
    }
    &__a {
      display: block;
      font-family: $rr;
      font-size: 14px;
      line-height: 1.29;
      color: #005bff;
      text-decoration: underline;
      margin-bottom: 8px;
    }
    &__strong {
      font-family: $rr;
      font-size: 14px;
      line-height: 1.43;
      color: #003087;
    }
    &__decryption {
      font-family: $rr;
      font-size: 10px;
      line-height: 1.6;
      color: #000;
    }
  }
}

.tooltipster-sidetip.tooltipster-noir.tooltipster-noir {
  &-customized {
    .tooltipster {
      &-box {
        border-radius: 6px;
        box-shadow: 0 2px 23px 0 #00308718;
        background-color: #ffffff;
        border: none;
      }
      &-content {
        position: relative;
        padding: 0;
        border-top-left-radius: 6px;
        border-top-right-radius: 6px;
      }
    }
  }
  &-a {
    .tooltipster {
      &-box {
        border-radius: 5px;
        //box-shadow: 0 2px 23px 0 #00308718;
        background-color: #ffffff;
        border: none;
      }
      &-content {
        position: relative;
        padding: 12px 19px;
        font-family: $rr;
        font-size: 14px;
        line-height: 1.29;
        color: #000;
      }
    }
  }
}
.tooltipster-sidetip.tooltipster-noir.tooltipster-noir-customized {
  .tooltipster {
    &-box {
      border-radius: 6px;
      box-shadow: 0 2px 23px 0 #00308718;
      background-color: #ffffff;
      border: none;
    }
    &-content {
      position: relative;
      padding: 0;
      border-top-left-radius: 6px;
      border-top-right-radius: 6px;
    }
  }
}

@media screen and (max-width: $tab-max) {
  .scheme {
    &-one {
      &-name {
        width: 155px;
        font-size: 11px;

        &_1 {
          width: 133px;
        }
        &_2 {
          left: 25.5%;
        }
        &_3 {
          left: 52.8%;
        }
        &_4 {
          left: 80.6%;
        }
        &_5 {
          left: 66.4%;
        }
      }
    }
    &-two {
      &-name {
        &_1 {
          left: -0.4%;
        }
        &_2 {
          left: 59.6%;
        }
        &_3 {
          left: 29%;
        }
        &_4 {
          width: 130px;
        }
        &_5 {
          left: 56.6%;
        }
        &_6 {
          left: 8%;
          width: 130px;
        }
        &_7 {
          left: 73%;
        }
        &_8 {
          left: 73%;
        }
        &_9 {
          left: -1.5%;
        }
        &_10 {
          left: 58%;
        }
        &_11 {
          width: 130px;
          left: 3.5%;
        }
        &_12 {
          left: 55.4%;
        }
      }
    }
  }
}

@media screen and (max-width: $tab-min) {
  .scheme {
    &-one {
      &-name {
        width: 80px;
        font-size: 7px;
        margin-top: -.5%;

        &_1 {
          width: 18%;
        }
        &_2 {
          width: 24%;
          left: 24%;
        }
        &_3 {
          left: 50.8%;
        }
        &_4 {
          left: 78.6%;
        }
        &_5 {
          left: 64.4%;
        }
      }
      &-icon {
        &_1 {
          top: 33%;
          left: 19%;
        }
        &_2 {
          top: 34%;
          left: 46.8%;
        }
        &_3 {
          top: 81%;
          left: 61.7%;
        }
        &_4 {
          top: 16.8%;
          left: 92.4%;
        }
        &_5 {
          top: 16.8%;
          left: 55%;
        }
        &_6 {
          width: 15%;
          height: 39%;
          left: 2%;
          top: 33%;
        }
        &_7 {
          width: 18%;
          height: 45%;
          left: 27%;
          top: 31%;
        }
        &_8 {
          width: 18%;
          height: 22%;
          left: 54%;
          top: 44%;
        }
        &_9 {
          width: 18%;
          height: 20%;
          left: 67.5%;
          top: 2%;
        }
        &_10 {
          width: 18%;
          height: 20%;
          left: 82%;
          top: 45%;
        }
        &_white,
        &_transparent,
        &_transparent:hover,
        &_white,
        &_white:hover{
          fill: transparent;
          color: transparent;

          circle {
            opacity: 0;
          }
        }
      }
    }
    &-two {
      &-name {
        width: 80px;
        font-size: 7px;
        margin-top: -.5%;

        &_1 {
          width: 150px;
          left: -7%;
        }
        &_2 {
          width: 150px;
          left: 53.6%;
        }
        &_3 {
          left: 18%;
        }
        &_4 {
          width: 100px;
        }
        &_5 {
          width: 160px;
          left: 51.6%;
        }
        &_6 {
          width: 113px;
        }
        &_7 {
          width: 111px;
          left: 72%;
        }
        &_8 {
          width: 111px;
          left: 72%;
        }
        &_9 {
          width: 156px;
          left: -6%;
        }
        &_10 {
          width: 150px;
          left: 53%;
        }
        &_11 {
          width: 100px;
        }
        &_12 {
          width: 150px;
          left: 53.4%;
        }
      }
    }
  }
}












































