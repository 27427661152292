.download-presentation {
  padding-top: 50px;
  padding-bottom: 50px;
  background-color: $main-blue;

  &__body {
    @include flex(row, space-between, flex-start);
  }

  &__text {
    width: 45%;
    font: 18px/1.56 $rr;
    max-width: 510px;
    color: white;
    padding-right: 40px;
  }

  &__form {
    width: 55%;
    @include flex(row, space-between, flex-start);
    flex-wrap: wrap;

    .swc-form-group {
      max-width: 400px;
      border: none;
      flex-grow: 2;
      margin-right: 6%;
      margin-bottom: 10px;
      &:last-child{
        margin-bottom: 0;
      }
      &__radio-wrapper {
        width: 100%;
        max-width: 400px;
        order: 3;
      }

      &__email {
        order: 1;
      }
    }

    .swc-btn {
      flex-grow: 1;
      order: 2;
    }

    a {
      color: #fff;
      text-decoration: underline;
    }
  }

  .swc-radio__label {
    color: white;
  }
}

@media screen and (max-width: $tab-max) {

  .download-presentation {

    &__body {
      @include flex(column, space-between, center);
    }

    &__text {
      width: 100%;
      padding: 0;
      margin-bottom: 30px;
      max-width: 100%;
      text-align: center;
      font-size: 24px;
      font-weight: bold;
      line-height: 1.5;
    }

    &__form {
      width: 100%;
      max-width: 330px;
      margin: auto;

      .swc-form-group {
        margin-right: 0;
        margin-bottom: 20px!important;
      }
    }
  }

}

@media screen and (max-width: $tab-min) {
  .download-presentation {
    &__text{
      font-size: 22px;
      line-height: 1.55;
    }
    &__form {
      flex-direction: column;
      width: 100%;
      max-width: 100%;

      .swc-form-group {
        width: 100%;
        max-width: 100%;
        margin-right: 0;
        margin-bottom: 20px;
      }

      .swc-btn {
        width: 100%;
        flex-grow: 1;
        order: 3;
        margin-top: 10px;
      }
    }

    & .swc-radio__wrap {
      margin-bottom: 10px;

      &:last-child {
        margin-bottom: 0;
      }
    }

  }
}

@media screen and (max-width: 425px) {



}




































