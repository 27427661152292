.invest-suggestion {
  padding-top: 163px;
  padding-bottom: 163px;
  //background-image: url('../img/bg/town.jpg');
  //background-size: cover;
  //background-position: top center;


  .container {
    position: relative;
    z-index: 1;
  }

  &__block {
    background-color: rgba(0, 48, 135, 0.8);
    color: white;
    width: 100%;
    max-width: 840px;
    margin: auto;
    padding: 128px 110px;
  }

  &__title {
    font: 32px/1.25 $rb;
    font-weight: bold;
    margin-bottom: 48px;
  }

  &__list {
    margin-bottom: 48px;

    &-item {
      font: 18px/1.56 $rr;
      margin-bottom: 25px;
      padding-left: 30px;
      position: relative;

      &:before {
        position: absolute;
        display: inline-block;
        content: '';
        width: 8px;
        height: 8px;
        background-color: white;
        border-radius: 50%;
        left: 0;
        top: 9px;
      }
    }
  }

  &__text {
    font: 18px/1.56 $rl;
    margin-bottom: 32px;
  }
}

@media screen and (max-width: $tab-max) {}

@media screen and (max-width: $tab-min) {

  .invest-suggestion {
    padding-top: 60px;
    padding-bottom: 60px;

    &__block {
      padding: 40px 30px;
    }

    &__title {
      font: 28px/1.25 $rb;
      font-weight: bold;
    }

  }

}































