.advantages {
  padding-top: 60px;
  //padding-bottom: 60px;
  background-color: #fff;

  &__title {
    font: 40px/1.4 $rb;
    font-weight: bold;
    margin-bottom: 20px;
    text-align: center;
  }
  
  &__subtitle {
    font: 24px/1.33 $rr;
    margin-bottom: 60px;
    text-align: center;
  }
  
  &__items {
    font-size: 0;
    max-width: 1020px;
    margin: auto;
  }
  
  &__item {
    font-size: initial;
    width: 50%;
    display: inline-block;
    vertical-align: top;
    padding-right: 40px;
    margin-bottom: 60px;

    &:nth-child(even) {
      padding-right: 0;
    }

    &-img {
      width: 80px;
      height: 80px;
      margin-bottom: 10px;
      text-align: center;

      &:before {
        width: 0;
        height: 100%;
        content: '';
        display: inline-block;
        vertical-align: bottom;
      }

      svg {
        max-width: 100%;
        max-height: 100%;
        display: inline-block;
        vertical-align: bottom;
      }
    }

    &-title {
      font: 24px/1.38 $rb;
      font-weight: bold;
      color: $main-blue;
      margin-bottom: 20px;
    }

    &-list {
      &-item {
        font: 16px/2 $rr;
        margin-bottom: 20px;
        position: relative;
        padding-left: 20px;

        &:before {
          display: block;
          content: '';
          position: absolute;
          width: 8px;
          height: 8px;
          border-radius: 50%;
          background-color: $main-blue;
          top: 12px;
          left: 0;
        }
      }
    }
  }
}

@media screen and (max-width: $tab-max) {
  .advantages {
    padding-bottom: 60px;

    &__items {
      max-width: 600px;
      margin: auto;
    }

    &__item {
      width: 100%;
      padding: 0;

      &:last-child {
        margin-bottom: 0;
      }

      &-img {
        margin: auto;
      }

      &-title {
        text-align: center;
      }
    }
  }
}

@media screen and (max-width: $tab-min) {

  .advantages {
    &__item {
      margin-bottom: 40px;
    }
  }

}



































