.contacts-map {
  width: 100%;
  @include flex(row, flex-start, initial);
  flex-wrap: wrap;
  padding: 80px 0 30px;

  table {
    width: 100%;
  }
  td {
    font-family: $rr;
    font-size: 20px;
    line-height: 1.4;
    padding-bottom: 24px;
    vertical-align: top;
    color: #00011d;

    &:first-child {
      font-family: $rl;
      white-space: nowrap;
      min-width: 210px;
      padding-right: 10px;
      color: #000;
    }
  }
  tr:last-child td {
    padding-bottom: 0;
  }

  &_link {
    font-family: $rr;
    font-size: 20px;
    color: #005bff;
    text-decoration: underline;
  }

  .swc-title {
    text-align: left;
    margin: 0;
  }

  &__item {
    width: 50%;
    padding: 64px 0;

    &:nth-child(2),
    &:nth-child(5){
      padding-right: 40px;
    }
    &:nth-child(3),
    &:nth-child(6){
      padding-left: 40px;
    }
  }

  &__map {
    //@include outr;
    //width: 40%;
    //height: 100%;
    //min-height: 500px;
    overflow: hidden;
    position: relative;
    width: 100%;
    min-height: 512px;
  }


  &__info {
    //@include outr;
    //width: 60%;
    //padding: 60px 60px 60px 8%;

    &-title {
      font: 24px $rb;
      font-weight: bold;
      line-height: 32px;
      margin-bottom: 40px;
      font-weight: bold;
    }

    &-subtitle {
      font: 20px/1.4 $rr;
      margin-bottom: 40px;
      text-transform: uppercase;
    }

    table {
      margin-bottom: 80px;

      &:last-child {
        margin-bottom: 0;
      }

      tr {
        td {
          padding-bottom: 24px;
          vertical-align: top;

          &:first-child {
            font: 20px/1.4 $rl;
            padding-right: 50px;
          }

          &:last-child {
            font: 20px/1.4 $rr;
          }
        }

        &:last-child {
          td {
            padding-bottom: 0;
          }
        }
      }
    }
    &-address {
      font-family: $rr;
      font-size: 20px;
      line-height: 1.4;
      color: #00011d;
      padding-bottom: 24px;
    }
  }

  &__social {
    &-links {
      @include flex(row, space-between, flex-start);
    }
    &-link {
      &:hover {
        text-decoration: none;

        path {
          fill: #005bff;
        }
      }
    }
    &-img {
       path {
         fill: #003087;
       }
    }
  }
}

[class*="ymaps-2"][class*="-ground-pane"] {
  filter: url("data:image/svg+xml;utf8,<svg xmlns=\'http://www.w3.org/2000/svg\'><filter id=\'grayscale\'><feColorMatrix type=\'matrix\' values=\'0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0 0 0 1 0\'/></filter></svg>#grayscale");
  /* Firefox 3.5+ */
  -webkit-filter: grayscale(100%);
  /* Chrome 19+ & Safari 6+ */
}

@media screen and (max-width: $tab-max) {

  .contacts-map {
    flex-direction: column;
    padding-top: 64px;

    .swc-title {
      margin: 0 60px 48px;
      width: auto;

    }
    &__item {
      width: 100%;
      padding: 0 60px 48px !important;

      &:nth-child(3){
        order: 1;
      }
      &:nth-child(5),
      &:nth-child(6){
        order: 2;
      }

    }
    &__map {
      margin-bottom: 48px;
    }

    //&__info {
    //  padding-left: 5%;
    //}
  }

}

@media screen and (max-width: $tab-min) {

  .contacts-map {
    flex-direction: column;
    padding-top: 32px;
    padding-bottom: 22px;

    .swc-title {
      margin: 0;
    }

    &__item {
      padding: 24px 0 16px;

      &:nth-child(2),
      &:nth-child(3),
      &:nth-child(5),
      &:nth-child(6) {
        padding: 16px 0 0 !important;
      }
      &:nth-child(2){
        padding-bottom: 16px !important;
      }
    }

    td {
      display: inline-block;
      font-size: 16px;
      padding-bottom: 16px;
      width: 100%;

      &:first-child {
        padding-bottom: 4px;
        white-space: normal;
      }
    }
    tr:last-child td {
      padding-bottom: 8px;
    }
    &__map {
      width: 100vw;
      min-height: 400px;
      margin: 0 -20px;
    }
    &__social {
      &-links {
        flex-wrap: wrap;
      }
      &-link {
        width: 20%;
        margin-bottom: 24px;
      }
    }

    &__info {

      &-title {
        margin-bottom: 16px;
        font-size: 20px;
      }
      &-address {
        padding-bottom: 16px;
        font-size: 16px;

        &_first {
          padding-bottom: 0;
        }
      }
    }
    &_link {
      font-size: 16px;
      line-height: 1.25;
    }
  }

}