.header {
	//	@include outg;
	position: fixed;
	left: 0;
	top: 0;
	right: 0;
	transition: top .35s ease;
	z-index: 100;
	//display: none;

	&_shadow {
		box-shadow: 0 5px 15px 0 rgba(0, 0, 0, .1);
	}
	
	&_hidden {
		top: -500px;
		bottom: 100%;
	}

	&__top {
		background-color: $main-blue;
		padding: 4px 0;
		color: white;
//		box-shadow: 0 5px 15px 0 rgba(0, 0, 0, .1);

		.container {
			@include flex;
			flex-wrap: nowrap;
		}

		a {
			color: white;
		}
	}

	&__call {
		@include inline-blocks;
	}

	&__phone {
		font-size: 18px;
		padding-right: 10px;
		margin-right: 10px;
		border-right: 1px solid;
	}

	&__callback {
		cursor: pointer;
		font-size: 18px;
		border-bottom: 2px dotted;
		font-family: $rr;
		text-decoration: none!important;
	}

	&__account {
		@include inline-blocks;
	}

	&__login {
		font-size: 18px;
		padding-right: 10px;
		margin-right: 10px;
		border-right: 1px solid;
	}

	&__reg {
		font-size: 18px;
		margin-right: 20px;
	}



	&__logo-img {
		display: block;
		width: 49px;
		height: 40px;
		margin: 11px 0;
	}

	&__menu {
		display: inline-block;
		width: 32px;
		height: 24px;
		position: relative;
		transform: rotate(0deg);
		transition: .1s ease-in-out;
		cursor: pointer;
		vertical-align: middle;
		margin-left: 15px;
		display: none;
		user-select: none;

		i {
			display: block;
			position: absolute;
			height: 4px;
			width: 100%;
			background: white;
			opacity: 1;
			left: 0;
			transform: rotate(0deg);
			transition: .2s ease-in-out;

			&:nth-child(1) {
				top: 0px;
				transform-origin: left center;
			}

			&:nth-child(2) {
				top: 0;
				bottom: 0;
				margin: auto;
				transform-origin: left center;
			}

			&:nth-child(3) {
				bottom: 0;
				transform-origin: left center;
			}
		}

		&.open {
			i {
				&:nth-child(1) {
					transform: rotate(45deg);
					top: -2px;
					left: 5px;
				}

				&:nth-child(2) {
					width: 0%;
					opacity: 0;
				}

				&:nth-child(3) {
					transform: rotate(-45deg);
					bottom: -1px;
					left: 5px;
				}
			}
		}
	}
}

@media screen and (max-width: $tab-max) {
	.header {
		
		//&.open {
		//	bottom: 0;
    	//	overflow-y: auto;
		//	background-color: $main-blue;
		//}

		&__phone,
		&__callback,
		&__login,
		&__reg {
			font-size: 16px;
		}

		&__phone,
		&__login {
			padding-right: 8px;
			margin-right: 8px;
		}
		&__reg {
			margin-right: 12px;
		}

	}
}

@media screen and (max-width: $tab-min) {
	.header {
		border-bottom: 1px solid #6181b9;

		&.open {
			bottom: 0;
			overflow-y: auto;
			background-color: $main-blue;
		}

		&__call {
			display: none;
		}

		&__logo-img {
			height: 25px;
		}

		&__account {
			display: none;
		}

		&__menu {
			display: inline-block;
		}

		&__lang {
			&-text {
				color: white;
			}
		}

	}
}

@media screen and (min-width: $tab-min) {

	.header {

		&__nav {
			display: block!important;
		}

	}

}
