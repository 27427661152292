.header {

	&__lang {
		cursor: pointer;
		user-select: none;
		position: relative;

		&-img {
			width: 32px;
			height: 24px;
			vertical-align: middle;
		}

		&-text {
			margin-left: 14px;
			color: white;
			vertical-align: middle;
		}

		&:hover {
			text-decoration: none;
		}
	}

	&__nav {

		&-langs-wrap {
			display: none;
			position: fixed;
			top: 0;
			left: 0;
			right: 0;
			z-index: 999;
			background-color: $main-blue;
			height: 100%;
			overflow: auto;
		}

		&-langs {
			//@include outr;
			//@include flex(row, flex-start, center);
			//flex-wrap: wrap;
			//text-align: center;
			max-width: 1020px;
			margin: auto;
			font-size: 0;

			&-top {
				position: relative;
				@include flex;
			}

			&-title {
				font: 36px/1.22 $rb;
				padding-top: 48px;
				padding-bottom: 48px;
				color: white;
				text-align: center;
				margin: 0 15px;
				font-weight: bold;
			}

			&-close {
				width: 64px;
				height: 64px;
				background-color: $main-blue-dark;
				cursor: pointer;
				position: relative;

				&:before, &:after {
					content: '';
					display: block;
					position: absolute;
					height: 4px;
					width: 50px;
					border-radius: 2px;
					background: white;
					opacity: 1;
					left: 0;
					transform: rotate(0deg);
					transform-origin: left center;
				}
				&:before {
					transform: rotate(45deg);
					top: 12px;
					left: 15px;
				}
				&:after {
					transform: rotate(-45deg);
					bottom: 12px;
					left: 15px;
				}
			}
		}

		&-lang {
			//@include outw;
			font-size: initial;
			display: inline-block;
			padding: 20px 32px;
			width: 10%;
			text-align: left;
			white-space: nowrap;
			min-width: 213px;
			color: white;
			margin: 20px;
			//flex-grow: 1;
			border: 2px solid transparent;
			transition: all .2s ease;
			cursor: pointer;

			&:hover {
				text-decoration: none;
				border: 2px solid #005bff;
			}

			&.current {
				background-color: #005bff;
			}

			&-text {
				font: 18px $rr;
			}
		}

	}

}

@media screen and (max-width: $tab-max) {
	.header {

		&__nav {

			&-langs {
				display: flex;
				flex-wrap: wrap;
				justify-content: space-between;
				padding: 0 100px;
			}
		}
	}
}

@media screen and (max-width: $tab-min) {

	.header {

		&__nav {

			&-langs {
				//@include flex(row, space-around, center);
				justify-content: center;
				padding: 0;

				&-title {
					font: 24px/1.22 $rb;
					font-weight: bold;
					padding: 13px 0;
				}

				&-close {
					width: 32px;
					height: 24px;
					background: transparent;

					&:before, &:after {
						height: 4px;
						border-radius: 0;
						width: 100%;
					}
					&:before {
						transform: rotate(45deg);
						top: -2px;
						left: 5px;
					}
					&:after {
						transform: rotate(-45deg);
						bottom: -1px;
						left: 5px;
					}
				}
			}

			&-lang {
				//@include outw;
				font-size: initial;
				display: inline-block;
				padding: 10px 32;
				width: 10%;
				text-align: left;
				white-space: nowrap;
				min-width: 213px;
				color: white;
				margin: 5px 0;
				//flex-grow: 1;
				border: 2px solid transparent;
				transition: all .2s ease;
			}

		}

	}

}







































