#etp-slider {
  background-color: transparent;
  padding: 0;
  max-width: 1440px;

 & .fancybox-close-small {
    width: 100px;
    height: 100px;

    & svg {
      width: 68px;
      height: 68px;
      stroke-width: 1;
      stroke: #ffffff;
      opacity: 1;

      &:hover {
        opacity: 0.5;
      }
    }
  }
}

@media screen and (max-width: $tab-max) {
  #etp-slider {
    max-width: 768px;

    & .fancybox-close-small {
      width: 50px;
      height: 50px;

      & svg {
        width: 34px;
        height: 34px;
      }
    }
  }
}

@media screen and (max-width: $tab-min) {
  #etp-slider {
    max-width: 375px;
  }
}