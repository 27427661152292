.mass-media {
  padding-top: 60px;
  padding-bottom: 120px;
  background-color: white;

  &__top {
    @include flex(row, space-between, center);
    margin-bottom: 60px;
  }

  &__title {
    font-family: $rb;
    font-weight: bold;
    font-size: 30px;
    color: $main-blue;
  }

  &__block {
    font-size: 0;
    padding-bottom: 30px;
    margin: 0 -20px;
  }

  &__item {
    width: 25%;
    text-align: center;
    font-size: initial;
    display: inline-block;
    padding: 0 20px;
    position: relative;

    &-img {
      @include flex(column, space-between, center);
      align-items: center;
      justify-content: center;
      //height: 200px;
      //margin-bottom: 10px;
      //background-color: #d8d8d8;
      position: relative;
      overflow: hidden;
      cursor: pointer;

      &:before {
        position: absolute;
        display: block;
        content: '';
        width: 61px;
        height: 61px;
        background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI2MSIgaGVpZ2h0PSI2MSIgdmlld0JveD0iMCAwIDYxIDYxIj4gICAgPGcgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIj4gICAgICAgIDxwYXRoIGZpbGw9IiMyMTY0RjQiIGQ9Ik0zMC41Ljc0NGMxNi40MzQgMCAyOS43NTYgMTMuMzIyIDI5Ljc1NiAyOS43NTZTNDYuOTM0IDYwLjI1NiAzMC41IDYwLjI1Ni43NDQgNDYuOTM0Ljc0NCAzMC41IDE0LjA2Ni43NDQgMzAuNS43NDR6Ii8+ICAgICAgICA8cGF0aCBmaWxsPSIjRkZGIiBkPSJNMzkuNjMyIDI5LjI0OGwtMTEuNjc1LTcuNDg5Yy0uMzQ1LS4yMDktLjc3My0uMTc0LTEuMDk3LS4xNzQtMS4yOTUgMC0xLjI4OSAxLjAxNy0xLjI4OSAxLjI3NXYxNS4zMDZjMCAuMjE4LS4wMDYgMS4yNzYgMS4yODkgMS4yNzYuMzI0IDAgLjc1Mi4wMzQgMS4wOTctLjE3NGwxMS42NzUtNy40OWMuOTU4LS41OC43OTItMS4yNjQuNzkyLTEuMjY0cy4xNjYtLjY4Ni0uNzkyLTEuMjY2eiIvPiAgICA8L2c+PC9zdmc+);
        top: 80px;
        right: 0;
        left: 0;
        margin: auto;
      }

      img {
        margin-bottom: 24px;
      }
    }

    &-title {
      font: 18px/1.56 $rr;
    }

  }
}

@media screen and (max-width: $tab-max) {}

@media screen and (max-width: $tab-min) {

  .mass-media {
    padding-bottom: 60px;

    &__block {
      padding-bottom: 50px;
    }

  }

}












































