.link-block {
  padding-top: 50px;
  padding-bottom: 50px;
  color: white;
  background-color: $main-blue;

  .container {
    @include flex(row, space-between, center);
    max-width: 880px;
  }

  &__text {
    font: 24px/1.42 $rb;
    font-weight: bold;
    //text-align: center;
  }

  .swc-btn {
    min-width: 290px;
  }
}

@media screen and (max-width: $tab-max) {}

@media screen and (max-width: $tab-min) {

  .link-block {

    .container {
      @include flex(column, space-between, center);
    }

    &__text {
      margin-bottom: 25px;
      text-align: center;
    }

  }

}
































