.footer {
//	@include outg;
	background-color: $main-blue-dark;
	color: white;
	flex-shrink: 0;
	position: relative;
	z-index: 1;

	a {
		color: white;
	}

	&__top {
		@include flex;
		padding: 30px 0;
		border-bottom: 1px solid #2d4783;
	}

	&__call {
		@include inline-blocks;
	}

	&__phone {
		font-size: 18px;
		padding-right: 10px;
		margin-right: 10px;
		border-right: 1px solid;
	}

	&__callback {
		cursor: pointer;
		font-size: 18px;
		font-family: $rr;

		&:hover {
			text-decoration: underline;
		}
	}

	&__account {
		@include inline-blocks;
	}

	&__login {
		font-size: 18px;
		//padding-right: 10px;
		margin-right: 30px;
		//border-right: 1px solid;
	}

	&__reg {
		font-size: 18px;
	}

	&__nav {

		&-links {
			width: 100%;
			@include flex;
			flex-wrap: nowrap;
			padding: 20px 0;
			border-bottom: 1px solid #2d4783;
		}

		&-link {
			font: 18px $rr;
			white-space: nowrap;
			padding: 10px 0;

			&-span {
				border-bottom: 2px dotted;
			}
		}

	}
	&__app{
		padding: 32px 0;
		border-bottom: 1px solid #2d4783;
		text-align: center;
		&-title{
			margin-bottom: 16px;
			font-weight: 700;
			font-size: 18px;
		}
		&-block{
			display: flex;
			flex-wrap: wrap;
			gap: 16px;
			justify-content: center;
		}
	}
	&__social {
		padding: 24px 0;
		border-bottom: 1px solid #2d4783;

		&-links {
			@include flex(row, space-around, center);
			max-width: 980px;
			margin: 0 auto;
			flex-wrap: wrap;
		}

		&-link {
			transition: all .2s ease;

			&:hover {
				opacity: .8;
			}
		}

		&-img {
			width: 30px;
			height: 30px;

			&_vm {
				height: 37px;
			}
			&_yt {
				height: 29px;
			}
			&_tg {
				height: 37px;
			}
		}

	}

	&__copy {
		padding: 30px 0;
		//text-align: center;

		&-text {
			font: 16px $rr;
			span {
				font: 16px $rr;
			}
		}
	}

	&__docs {
		padding-top: 48px;
		padding-bottom: 28px;
		border-bottom: 1px solid #2d4783;
		font-size: 0;

		a {
			font: 18px $rr;
			display: inline-block;
			vertical-align: top;
			width: 23%;
			margin-right: 2%;
			margin-bottom: 20px;
			text-decoration: underline;
		}
	}
}

@media screen and (max-width: $tab-max) {
	.footer {

		&__phone,
		&__callback,
		&__login,
		&__reg {
			font-size: 16px;
		}

		&__phone,
		&__login {
			padding-right: 8px;
			margin-right: 8px;
		}

		&__reg {
			margin-right: 12px;
		}
		
		&__nav {

			&-link {
				font-size: 16px;
			}

		}

		&__docs {
			a {
				width: 31%;
			}
		}

	}
}

@media screen and (max-width: $tab-min) {
	.footer {
		
		&__top {
			padding: 30px 0;
			flex-direction: column-reverse;
		}
		
		&__account {
			@include flex(column, space-between, center);
			margin-bottom: 30px;
		}
		&__login {
			padding: 0;
			margin: 0 0 10px;
			border: none;
		}
		
		&__call {
			@include flex(column, space-between, center);
		}
		&__phone {
			padding: 0;
			margin: 0 0 10px;
			border: none;
		}

		&__nav {

			&-links {
				padding: 30px 0;
				border-bottom: none;
				@include flex(column, space-between, center);
			}

		}
		
		&__social {
			padding-bottom: 0;

			&-links {
				@include flex(row, center, center);
			}
			
			&-link {
				width: 80px;
				text-align: center;
				margin-bottom: 20px;
			}

			&-img {
				width: 32px;
				height: 32px;
			}

		}
		
		&__copy {

			&-text {
				font-size: 14px;
			}
			
			span {
				display: block;
			}
		}

		&__docs {
			a {
				font-size: 14px;
				width: 100%;
				margin-right: 0;
			}
		}

	}
}








































