.personal-account {
  padding-top: 120px;
  padding-bottom: 105px;
  //background: url("../img/bg/earth.jpg") no-repeat top center;
  //background-size: cover;
  color: white;

  .container {
    max-width: 1060px;
    position: relative;
    z-index: 1;
  }

  &__title {
    font: 36px/1.56 $rl;
    margin-bottom: 80px;
    max-width: 620px;
  }

  &__items {
    font-size: 0;
  }

  &__item {
    font-size: initial;
    display: inline-block;
    vertical-align: top;
    max-width: 440px;
    padding-left: 20px;
    position: relative;
    margin-bottom: 55px;
    padding-right: 40px;

    &:before {
      position: absolute;
      display: block;
      content: '';
      width: 8px;
      height: 8px;
      border-radius: 50%;
      background-color: white;
      top: 10px;
      left: 0;
    }

    &-title {
      font: 24px $rr;
      margin-bottom: 15px;
      display: block;
    }

    &-text {
      font: 16px/1.5 $rr;
      display: block;
    }
  }

  &__btn {
    padding-left: 20px;
  }
}

@media screen and (max-width: $tab-max) {}

@media screen and (max-width: $tab-min) {

  .personal-account {
    padding-top: 60px;
    padding-bottom: 60px;

    &__title {
      font: 28px/1.56 $rr;
    }
  }

}


































