.intl-tel-input {
  width: 100%;

  &.allow-dropdown .flag-container, &.separate-dial-code .flag-container {
    left: 0;
  }

  .selected-flag {
    padding: 0 15px 0 20px;

    &:focus {
      outline: 1px solid $btn;
    }
  }

  .country-list {
    background-color: #ffffff;
    border: solid 1px rgba(137,210,255,.45);

    .divider {
      border-bottom: solid 1px rgba(137,210,255,.45);
    }
  }
}

.iti-flag {
  box-shadow: 0 0 1px 0 rgba(137,210,255,.45);
}

@media (max-width: $tab-min) {

  .intl-tel-input {

    .selected-flag {
      padding: 0 0 0 16px;
    }

  }

  .intl-tel-input.allow-dropdown input, .intl-tel-input.allow-dropdown input[type=text], .intl-tel-input.allow-dropdown input[type=tel], .intl-tel-input.separate-dial-code input, .intl-tel-input.separate-dial-code input[type=text], .intl-tel-input.separate-dial-code input[type=tel] {
    padding-left: 42px;
  }

}