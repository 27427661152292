.content_404 {
	padding: 114px 0;
	position: relative;
	overflow: hidden;

	&:before {
		position: absolute;
		display: block;
		content: '';
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		background-color: rgba(0, 48, 135, 0.65);
		z-index: 1;
	}
	&-video {
		position: absolute;
		top: -100%;
		bottom: -100%;
		left: -100%;
		right: -100%;
		margin: auto;
		min-height: 100%;
		min-width: 100%;
		z-index: 0;
	}
	&_video {
		position: relative;
		z-index: 1;
	}
	&-text {
		position: relative;
		z-index: 1;
		max-width: 840px;
		margin: 0 auto;
	}
	&-h1 {
		font-family: $rb;
		font-weight: bold;
		font-size: 48px;
		line-height: 1.33;
		color: #fff;
		margin-bottom: 96px;

	}
	&-h3 {
		font-family: $rr;
		font-size: 32px;
		line-height: 1.38;
		color: #fff;
		margin-bottom: 64px;

	}
	&-list{

		&-li {
			display: inline-block;
			width: 49%;
			vertical-align: top;
			padding:0 40px 0 0;
			margin:0 0 64px;
		}
		&-link {
			font-family: $rb;
			font-weight: bold;
			font-size: 24px;
			line-height: 1.33;
			color: #fff;
			text-decoration: underline;
			margin-bottom: 16px;
		}
		&-text {
			font-family: $rr;
			display: block;
			font-size: 18px;
			line-height: 1.33;
			color: #fff;
			margin-top: 16px;
		}

	}
}

@media screen and (max-width: 768px) {
	.content_404 {
		&-text {
			max-width: 595px;

		}
	}
}

@media screen and (max-width: 375px) {
	.content_404 {
		padding: 57px 0;
		&-text {

		}
		&-h1 {
			font-size: 32px;
			line-height: 1.25;
			margin-bottom: 80px;
		}
		&-h3 {
			font-size: 24px;
			line-height: 1.33;
			margin-bottom: 40px;

		}
		&-list{
			&-link {
				margin-bottom: 8px;
			}
			&-li {
				width: 100%;
				padding: 0;
				margin-bottom: 40px;
			}
		}
	}
}