.eco-techno-park {
  background-color: #fafbfc;
  padding-top: 80px;
  padding-bottom: 60px;

  .swc-subtitle {
    margin-bottom: 80px;
  }

  &__img-wrap {
    //@include outg;
    max-width: 1440px;
    margin: auto;
  }

  &__img {
    padding-top: 56.32%;
    background: url('../img/bg/eco-techno-park.jpg') no-repeat center;
    background-size: cover;
    position: relative;
  }
  &__video {
    width: 510px;
    height: 287px;
  }
  
  &__btn {
    position: absolute;
    bottom: 50px;
    left: 0;
    right: 0;
    text-align: center;

    .swc-btn {
      min-width: 290px;
    }
  }

  &__list {
    .swc-pin {
      &:nth-child(1) {
        top: 33%;
        left: 46%;
      }
      &:nth-child(2) {
        top: 30%;
        left: 62%;
      }
      &:nth-child(3) {
        top: 43%;
        left: 59%;
      }
      &:nth-child(4) {
        top: 74%;
        left: 56%;
      }
      &:nth-child(5) {
        top: 36%;
        left: 75%;
      }
      &:nth-child(6) {
        top: 19%;
        left: 60%;
      }
      &:nth-child(7) {
        top: 70%;
        left: 57%;
      }
      &:nth-child(8) {
        top: 48%;
        left: 69%;
      }
      &:nth-child(9) {
        top: 17%;
        left: 72%;
      }
      &:nth-child(10) {
        top: 12%;
        left: 77%;
      }
    }
  }
}

@media screen and (max-width: $tab-max) {

  .eco-techno-park {

    &__btn {
      bottom: 20px;
    }

  }

}

@media screen and (max-width: $tab-min) {

  .eco-techno-park {
    padding-top: 60px;

    &__btn {
      bottom: 5px;

      .swc-btn {
        padding: 4px 25px;
        min-width: auto;
      }

    }
    &__video {
      max-width: 100vw;
    }
  }
}














































