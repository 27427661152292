html, body {
	height: 100%;
}

body {
	background-color: white;
	display: flex;
	flex-direction: column;
}

.container {
	max-width: 1320px;
	min-width: 320px;
	margin: 0 auto;
	padding: 0 24px;

	&_preview {
		padding-right: 0;
	}

	&_wide {
		//max-width: 1440px;
		//padding: 0;
	}
	&_news {

	}
}

@media screen and (max-width: $tab-max) {
	.container {
		&_news {
			padding: 0 24px;
		}
	}
}

@media screen and (max-width: $tab-min) {
	.container {
		padding: 0 19px 0 16px;
		&_news {
			margin: 0;
			width: 100%;
			padding: 36px 16px 0 !important;

		}
	}
}