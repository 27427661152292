.top-video {
  padding-top: 240px;
  padding-bottom: 240px;
  position: relative;
  overflow: hidden;

  &:before {
    display: block;
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: rgba(0, 48, 135, 0.65);
    z-index: 1;
  }

  video {
    position: absolute;
    top: -100%;
    bottom: -100%;
    left: -100%;
    right: -100%;
    margin: auto;
    min-height: 100%;
    min-width: 100%;
  }

  &__text {
    position: relative;
    width: 100%;
    max-width: 1060px;
    margin: auto;
    text-align: center;
    color: white;
    font: 36px/1.56 $rb;
    font-weight: bold;
    z-index: 2;
  }
}

@media screen and (max-width: $tab-max) {}

@media screen and (max-width: $tab-min) {

  .top-video {
    padding-top: 120px;
    padding-bottom: 120px;
    background: url('../img/bg/top-video_sm.jpg') no-repeat top center;
    background-size: cover;

    video {
      display: none;
    }

    &__text {
      font-size: 24px;
    }

  }

}





































