// шрифты
$rth: 'Roboto Thin', sans-serif;	//100
$rl: 'Roboto Light', sans-serif;	//300
$rr: 'Roboto Regular', sans-serif;	//400
$rm: 'Roboto Medium', sans-serif;	//500
$rb: 'Roboto Bold', sans-serif;		//700
$rbl: 'Roboto Black', sans-serif;	//900

$ns: 'Noto Serif', serif;	//400
$nsi: 'Noto Serif Italic', serif;	//400
$nsb: 'Noto Serif Bold', serif;		//700
$nsbi: 'Noto Serif Bold Italic', serif;		//700

@mixin outw {
	outline: 1px solid white;
}
@mixin outg {
	outline: 1px solid #070;
}
@mixin outr {
	outline: 1px solid #c00;
}

// цвета
$main-blue: #003087;
$main-blue-light: #2164f4;
$main-blue-dark: #012169;

$link-color: #012169;

$btn: #2164f4;
$btn-hover: #0038ff;
$btn-active: #2164f4;

$arr-hover: #012169;

// разрешения
$tab-max: 1024px;
$tab-min: 767px;

// mixins
@mixin inline-blocks {
	 font-size: 0;
	 
	 & > * {
			font-size: initial;
			display: inline-block;
			vertical-align: middle;
	 }
}

input[type='text'] {
	&::-webkit-input-placeholder       {opacity: 1; transition: opacity 0.2s ease;}
	&::-moz-placeholder                {opacity: 1; transition: opacity 0.2s ease;}
	&:-moz-placeholder                 {opacity: 1; transition: opacity 0.2s ease;}
	&:-ms-input-placeholder            {opacity: 1; transition: opacity 0.2s ease;}
	&:focus::-webkit-input-placeholder {opacity: 0; transition: opacity 0.2s ease;}
	&:focus::-moz-placeholder          {opacity: 0; transition: opacity 0.2s ease;}
	&:focus:-moz-placeholder           {opacity: 0; transition: opacity 0.2s ease;}
	&:focus:-ms-input-placeholder      {opacity: 0; transition: opacity 0.2s ease;}
}

@mixin placeholder-color($color) {
	&::-moz-placeholder {
		color: $color;
	}
	&::-webkit-input-placeholder {
		color: $color;
	}
}

@mixin flex($flex-direction: row, $justify-content: space-between, $align-items: center) {
	display: flex;
	flex-direction: $flex-direction;
	justify-content: $justify-content;
	align-items: $align-items;
}

@mixin if_items($pieces) {
	&:nth-last-child(#{$pieces}):first-child,
	&:nth-last-child(#{$pieces}):first-child ~ *{
		width: calc(100%/ #{$pieces});
	}
}

@mixin justify-blocks {
	text-align: justify;
	font-size: 0;
	&:after {
		content: '';
		display: inline-block;
		width: 100%;
		height: 0;
		visibility: hidden;
	}
	& > * {
		font-size: initial;
		display: inline-block;
		vertical-align: middle;
	}
}

// styles
[hidden], .hidden {
	display: none;
}
.mb10{
	margin-bottom: 10px!important;
}
.mb0{
	margin-bottom: 0!important;
}



































