.stages {
  padding-top: 60px;
  padding-bottom: 120px;
  background-color: #fafbfc;

  &__line {
    font-size: 0;
    margin-bottom: 80px;
  }

  &__stage {
    display: inline-block;
    vertical-align: top;
    width: 6.66667%;
    text-align: center;
    cursor: pointer;
    user-select: none;
    font: 20px $rr;
    color: white;
    color: $main-blue;
    background-color: $main-blue-light;
    background-color: white;
    border: 1px solid $main-blue;
    border-right: none;
    padding: 7px 0;
    transition: all .2s ease;

    &.fill {
      background-color: $main-blue-light;
      color: white;
    }

    &.not-active {
      background-color: white;
      color: $main-blue;
      //cursor: not-allowed;

      &:hover {
        background-color: $main-blue;
        color: white;
      }

      &.active {
        color: white;
      }
    }

    &.active, &:hover {
      color: white;
      background-color: $main-blue;
    }

    &:first-child {
      border-bottom-left-radius: 2px;
      border-top-left-radius: 2px;
    }

    &:last-child {
      border-bottom-right-radius: 2px;
      border-top-right-radius: 2px;
      border-right: 1px solid $main-blue;
    }
  }

  &__slider {
    white-space: nowrap;
    overflow: hidden;
    margin: 0 -10px;

    &-body {
      @include flex(row, space-between, flex-start);
      overflow: hidden;
    }

    &-title {
      font: 24px/1.38 $rb;
      font-weight: bold;
      margin-bottom: 20px;
      color: $main-blue;
    }

    &-info {
      //@include outg;
      width: 50%;
      padding-right: 40px;

      p {
        font: 16px/2 $rr;
        margin-bottom: 15px;

        &:last-child {
          margin-bottom: 0;
        }
      }

      ul {
        margin-bottom: 15px;

        &:last-child {
          margin-bottom: 0;
        }

        li {
          font: 16px/2 $rr;
          margin-bottom: 10px;
          padding-left: 20px;
          position: relative;

          &:before {
            position: absolute;
            display: block;
            content: '';
            width: 8px;
            height: 8px;
            border-radius: 50%;
            background-color: $main-blue;
            top: 11px;
            left: 0;
          }
        }
      }
    }

    &-thumb {

    }

    &-imgs {
      //@include outg;
      width: 50%;
      max-width: 620px;

      &-top {
        margin-bottom: 30px;

        img {
          width: 100%;
        }
      }

      &-bot {
        //@include outr;
        margin: 0 -20px;
        //@include flex;
        //flex-wrap: wrap;
        //white-space: nowrap;
        max-height: 210px;
        //overflow: hidden;
        font-size: 0;
        padding-bottom: 30px;

        a {
          display: inline-block;
          vertical-align: top;
          width: 100%;
          max-width: 180px;

          img {
            width: 100%;
          }
        }
      }

      &-thumb {
        //@include outg;
        width: 33.33%;
        padding: 0 20px;
        display: inline-block;
        vertical-align: top;
        text-align: center;
        cursor: pointer;
      }
    }

  }

  &__slide {
    white-space: normal;
    display: inline-block;
    vertical-align: top;
    padding: 0 10px;
  }
}

@media screen and (max-width: $tab-max) {

  .stages {

    &__slider {

      &-body {
        flex-direction: column;
      }

      &-info {
        width: 100%;
        padding: 0;
        margin-bottom: 30px;
      }

      &-imgs {
        width: 100%;
        margin: auto;
      }

    }

  }

}

@media screen and (max-width: $tab-min) {

  .stages {
    padding-bottom: 60px;

    &__line {
      margin: 0 -15px 40px;
    }

    &__stage {
      font-size: 14px;
    }

    &__slider {

      &info {

        p {
          font: 16px/1.7 $rr;
          margin-bottom: 20px;
        }

      }

      &-imgs {

        &-bot {
          margin: 0 -10px;
        }

        &-thumb {
          padding: 0 10px;
        }
      }

    }

  }

}









































