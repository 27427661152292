.transport-types {
  padding-top: 60px;
  padding-bottom: 60px;
  background-color: white;

  &__slider {
  }

  &__slide {
    width: 100%;

    &:not(:first-child) {
      display: none;
    }
  }

  &__slide-top {
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    position: relative;
    //margin-bottom: 50px;

    img {
      width: 100%;
      display: inline-block;
      vertical-align: top;
    }

    .swc-slider-arrow {
      position: absolute;
      top: 32%;
      z-index: 1;

      &_prev {
        left: 5px;
      }

      &_next {
        right: 5px;
      }
    }
  }

  &__slide-bot {
    font-size: 0;
    @include flex(row, space-between, flex-start);
    margin-top: 50px;

    &-title {
      font: 40px/1.2 $rb;
      font-weight: bold;
      margin-bottom: 15px;
    }

    &>* {
      font-size: initial
    }

    &-info {
      //width: 40%;
      padding-right: 20px;
      white-space: normal;

      p {
        font: 16px/2 $rr;
        padding-bottom: 10px;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }

    &-video {
      display: inline-block;
      vertical-align: top;
    }

    &-gallery {
      //@include outr;
      display: inline-block;
      vertical-align: top;
      max-width: 400px;
      overflow: hidden;
      white-space: nowrap;

      div {
        display: inline-block;
        vertical-align: top;
        width: 100%;
      }
    }

    &-imgs {
      white-space: nowrap;
      font-size: 0;
      //width: 60%;
      //@include flex(row, flex-end, center);
      //flex-wrap: wrap;
    }

    &-img {
      margin-right: 40px;
      margin-bottom: 40px;
      display: inline-block;
      vertical-align: top;
      max-width: 180px;
      max-height: 180px;
      cursor: pointer;

      &:last-child:not(:first-child) {
        margin-right: 0;
      }

      &_video {
        position: relative;

        &:before {
          position: absolute;
          display: block;
          content: '';
          width: 100%;
          height: 100%;
          border: 3px solid #2164f4;
          top: 0;
          left: 0;
          box-sizing: border-box;
        }

        &:after {
          position: absolute;
          display: block;
          content: '';
          width: 61px;
          height: 61px;
          background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI2MSIgaGVpZ2h0PSI2MSIgdmlld0JveD0iMCAwIDYxIDYxIj4gICAgPGcgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIj4gICAgICAgIDxwYXRoIGZpbGw9IiMyMTY0RjQiIGQ9Ik0zMC41Ljc0NGMxNi40MzQgMCAyOS43NTYgMTMuMzIyIDI5Ljc1NiAyOS43NTZTNDYuOTM0IDYwLjI1NiAzMC41IDYwLjI1Ni43NDQgNDYuOTM0Ljc0NCAzMC41IDE0LjA2Ni43NDQgMzAuNS43NDR6Ii8+ICAgICAgICA8cGF0aCBmaWxsPSIjRkZGIiBkPSJNMzkuNjMyIDI5LjI0OGwtMTEuNjc1LTcuNDg5Yy0uMzQ1LS4yMDktLjc3My0uMTc0LTEuMDk3LS4xNzQtMS4yOTUgMC0xLjI4OSAxLjAxNy0xLjI4OSAxLjI3NXYxNS4zMDZjMCAuMjE4LS4wMDYgMS4yNzYgMS4yODkgMS4yNzYuMzI0IDAgLjc1Mi4wMzQgMS4wOTctLjE3NGwxMS42NzUtNy40OWMuOTU4LS41OC43OTItMS4yNjQuNzkyLTEuMjY0cy4xNjYtLjY4Ni0uNzkyLTEuMjY2eiIvPiAgICA8L2c+PC9zdmc+);
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          margin: auto;
        }
      }
    }
  }
}

@media screen and (max-width: $tab-max) {

  .transport-types {
    &__slide-bot {
      @include flex(column-reverse, flex-start, flex-start);

      &-info {
        width: 100%;
      }

      &-imgs {
        width: 100%;
        @include flex(row, center, center);
      }

      &-img {
        margin-left: 0;
        margin-right: 40px;
      }
    }
  }

}

@media screen and (max-width: $tab-min) {

  .transport-types {
    &__slide-bot{
      &-title {
        font: 30px/1.2 $rb;
        font-weight: bold;
      }

      &-gallery {
        max-width: 215px;
      }

      &-img {
        margin-right: 15px;
        margin-bottom: 25px;
        width: 100px;
        height: 100px;

        img {
          width: 100%;
        }
      }
    }
  }

}







































