.tooltipster-sidetip .tooltipster-box {
  background: transparent;
  border: 0 solid transparent;
  padding: 0;
}
.tooltipster-sidetip .tooltipster-content {
  padding: 5px;
}
.tooltipster-sidetip.tooltipster-top .tooltipster-box {
  margin-bottom: 0;
}
.tooltipster-sidetip.tooltipster-bottom .tooltipster-arrow-background {
  border-bottom-color: transparent;
  left: 0;
  top: 0;
}