.popup {
  width: 100%;
  max-width: 840px;
  padding: 96px 110px;
  //margin-right: 100px;
  overflow: initial;

  .fancybox-close-small {
    //right: -100px;
  }

  &__title {
    font: 32px/1.28 $rb;
    font-weight: bold;
    text-align: center;
    margin-bottom: 40px;
  }

  &__text {
    font: 24px $rl;
    text-align: center;
  }

  form {
    width: 100%;
    max-width: 400px;
    margin: auto;

  }
  .swc-form-group{
    margin-bottom: 20px;
  }
  .swc-btn{
    margin-top: 20px;
    background-color: #005bff;
    &:disabled{
      background-color: #7ca3f9;
      border-color: #7ca3f9;
      border-radius: 2px;
    }
  }
}
input:-webkit-autofill {
  -webkit-box-shadow: inset 0 0 0 50px white;
  -webkit-text-fill-color: #012169;
}
input:-webkit-autofill:focus {
  -webkit-box-shadow: 0 0 0px 0    white,
  inset 0 0 0 50px white;
  -webkit-text-fill-color: #012169;
}
@media screen and (max-width: $tab-max) {}

@media screen and (max-width: $tab-min) {

  .popup {
    padding: 60px 20px 40px;
    button {
      svg {
        width: 30px;
        height: 30px;
      }
    }
  }

}