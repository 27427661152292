.team {
  padding: 450px 80px 90px;

  //background-attachment: fixed;
  color: white;
  position: relative;
  overflow: hidden;

  &__title {
    font: 40px/1.4 $rb;
    font-weight: bold;
    margin-bottom: 20px;
    max-width: 730px;
    position: relative;
    z-index: 2;
  }

  &__text {
    font: 24px/1.33 $rb;
    font-weight: bold;
    max-width: 730px;
    position: relative;
    z-index: 2;
  }

  //&__bg {
  //  height: 125%;
  //  width: 100%;
  //  position: absolute;
  //  top: -50%;
  //  left: 0;
  //  right: 0;
  //  margin: auto;
  //  background: url(../img/bg/team1.jpg) center no-repeat;
  //  background-size: cover;
  //  z-index: 0;
  //}
}

@media screen and (max-width: $tab-max) {

  .team {
    padding: 200px 40px 90px;

    &__title {
      font: 30px/1.4 $rb;
      font-weight: bold;
      margin-bottom: 20px;
      max-width: 730px;
    }

    &__text {
      font: 18px/1.33 $rb;
      font-weight: bold;
      max-width: 730px;
    }

  }

}

@media screen and (max-width: $tab-min) {



}















































