.why-partner {
  //padding-top: 200px;
  height: 100vh;
  color: white;
  position: relative;
  z-index: 10;
  overflow: hidden;
  background: #011643;
  @include flex(row, space-between, center);

  &__bgs {

  }

  &__bg {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    &:not(:first-child) {
      opacity: 0;
      display: none;
    }

    &-1 {
      background: url('../img/partner/1.jpg') bottom center no-repeat;
      background-size: cover;
    }
    &-2 {
      background: url('../img/partner/2.jpg') bottom center no-repeat;
      background-size: cover;
    }
    &-3 {
      background: url('../img/partner/3.jpg') bottom center no-repeat;
      background-size: cover;
    }
    &-4 {
      background: url('../img/partner/4.jpg') bottom center no-repeat;
      background-size: cover;
    }
  }

  &__title {
    font: 40px/1.2 $rb;
    font-weight: bold;
    width: 100%;
    max-width: 800px;
    margin: 0 auto 10vh;
    position: relative;
    z-index: 1;
    padding: 0 0 0 150px;
  }

  &__blocks {
    white-space: nowrap;
    width: 100%;
    max-width: 800px;
    margin: auto;

    &-wrap {
      position: relative;
      width: 100%;
      max-width: 800px;
      margin: auto;
    }
  }

  &__block {
    white-space: normal;
    width: 100%;
    display: inline-block;
    vertical-align: top;
    opacity: 1;
    //margin-right: 100px;

    &:not(:first-child) {
      //right: -100%;
      opacity: 0;
    }

    &:last-child {
      margin-right: 0;
    }

    &-title {
      font: 24px/1.33 $rb;
      font-weight: bold;
      margin-bottom: 3vh;
      padding-right: 150px;
      padding-left: 150px;
    }

    &-text {
      font: 20px/1.6 $rr;
      padding-right: 150px;
      padding-left: 150px;
    }

  }

  &__triggers {
    position: relative;
  }

  &__trigger {
    position: absolute;
    outline: 1px solid #c00;
    height: 0;
    width: 0;
    left: 0;
    z-index: 10;

    &:nth-child(1) {
      top: 300px;
    }
    &:nth-child(2) {
      top: 600px;
    }
    &:nth-child(3) {
      top: 900px;
    }
  }

  &__bg-trigger {
    position: absolute;
    //outline: 1px solid #c00;
    height: 0;
    width: 0;
    left: 0;
    z-index: 10;

    &:nth-child(1) {
      top: 150px;
    }
    &:nth-child(2) {
      top: 450px;
    }
    &:nth-child(3) {
      top: 750px;
    }
  }
}

@media screen and (max-width: $tab-max) {}

@media screen and (max-width: $tab-min) {

  .why-partner {
    //padding-top: 70px;

    &__title {
      font: 24px/1.33 $rb;
      font-weight: bold;
      text-align: center;
      padding: 0;
      margin: 0 auto 7vh;
    }

    &__blocks {
      white-space: nowrap;
      width: 100%;
      max-width: 600px;
      margin: auto;

      &-wrap {
        position: relative;
        width: 100%;
        max-width: 600px;
        margin: auto;
      }
    }

    &__block {

      &-title {
        font: 20px/1.33 $rr;
        margin-bottom: 3vh;
        padding: 0 20px;
      }

      &-text {
        font: 18px/1.33 $rr;
        padding: 0 20px;
      }

    }
  }

}