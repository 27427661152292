.video-block {
	padding-top: 100px;
	padding-bottom: 100px;
	background-color: #fafbfc;

	&_blue {
		padding-top: 115px;
		background: linear-gradient(to bottom, #e0e6f0 0, #fafbfc 100%);
		padding-bottom: 115px;

		.video-block {
			&__video-wrap {
				width: 100%;
				max-width: 620px;
			}

			&__info-wrap {
				width: 100%;
				max-width: 620px;
			}

			&__list {
				&-item {
					font: 16px/1.75 $rr;
					margin-bottom: 15px;
				}
			}
		}
	}

	&__note {
		font: 16px/1.5 $rr;
		color: $main-blue;
		margin-top: 12px;
	}

	&__wrap {
		@include flex(row, space-between, initial);
	}

	&__info-wrap {
		//outline: 1px solid #c00;
		width: 34%;
		padding-right: 40px;
		align-self: center;
	}

	&__info {
		margin-bottom: 50px;
	}

	&__title {
		font: 40px $rb;
		font-weight: bold;
		margin-bottom: 40px;

		&_mobile {
			display: none;
		}
	}

	&__subtitle {
		font: 24px/1.33 $rb;
		font-weight: bold;
		color: $main-blue;
		margin-bottom: 20px;
	}

	&__text {
		font-size: 20px;
	}

	&__list {
		margin-bottom: 20px;

		li {
			padding-left: 20px;
			position: relative;
			margin-bottom: 25px;
			font: 18px/1.56 $rr;

			&:before {
				display: block;
				content: '';
				width: 8px;
				height: 8px;
				background-color: $main-blue;
				position: absolute;
				top: 10px;
				left: 0;
				border-radius: 50%;
			}

			&:last-child {
				margin-bottom: 0;
			}
		}
	}

	&__video-wrap {
		width: 66%;
		max-width: 720px;
	}

	&__video {
//		outline: 1px solid #c00;
		width: 100%;
		position: relative;
		overflow: hidden;
		//box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.2);
		padding-top: 75%;
		background-color: #d8d8d8;
		
		video {
			position: absolute;
			top: -100%;
			bottom: -100%;
			left: -100%;
			right: -100%;
			margin: auto;
			height: 100%;
			min-height: 100%;
			min-width: 100%;
		}

		img {
			width: 100%;
			display: none;
		}
		
		&-trigger {
//			outline: 1px solid #c00;
			position: absolute;
			width: 100%;
			height: 0;
			z-index: 100;
			top: 100%;
			left: 0;
			right: 0;
		}
		
		.swc-video-btn_play {
			position: absolute;
			bottom: 50px;
			right: 50px;
			display: none;
		}
	}

	&__link {
		box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.2);

		a {
			img {
				width: 100%;
			}
		}
	}
}

@media screen and (max-width: $tab-max) {
	.video-block {
		
		&__wrap {
			@include flex(column-reverse, space-between, initial);
		}
		
		&__video {
		}

		&__video-wrap {
			width: 100%;
			margin: 0 auto 25px;
			max-width: 636px;
		}
		
		&__info {
			margin-bottom: 20px;
			
			&-wrap {
				padding-right: 0;
				width: 100%;
				max-width: 636px;
			}
		}
		
		&__title {
			font-size: 24px;
			margin-bottom: 20px;
			display: none;
			
			&_mobile {
				display: block;
				max-width: 636px;
				margin: 0 auto 20px;
			}
		}
		
		&__list {
			&-item {
				font-size: 16px;
			}
		}
		
		&__text {
			font-size: 16px;
		}
		
	}
}

@media screen and (max-width: $tab-min) {

	.video-block {
		padding-top: 60px;
		padding-bottom: 60px;

		&__video {

			video {
				//display: none;
			}

			img {
				//display: block;
			}

			.swc-video-btn_play {
				//display: none!important;
				display: block;
				right: 15px;
				bottom: 15px;
			}
		}

		&__list {
			li {
				margin-bottom: 15px;
			}
		}
	}

}

































