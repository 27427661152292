.investors {
  padding-top: 120px;
  padding-bottom: 55px;
  position: relative;
  overflow: hidden;

  .container {
    position: relative;
    z-index: 1;
  }

  //&__bg {
  //  height: 150%;
  //  width: 100%;
  //  position: absolute;
  //  top: -50%;
  //  left: 0;
  //  right: 0;
  //  margin: auto;
  //  background: $main-blue url(../img/bg/flags.jpg) center no-repeat;
  //  background-size: cover;
  //  z-index: -1;
  //}

  &__items {
    font-size: 0;
  }

  &__item {
    color: white;
    font-size: initial;
    text-align: center;
    width: 33.33%;
    display: inline-block;
    vertical-align: top;
    padding: 0 40px;
    margin-bottom: 65px;

    &-img {
      //@include outr;
      width: 180px;
      height: 180px;
      border-radius: 50%;
      overflow: hidden;
      margin: auto;
      margin-bottom: 24px;
    }

    &-name {
      font: 24px $rb;
      font-weight: bold;
      margin-bottom: 10px;
    }

    &-text {
      font: 16px/1.5 $rr;
    }
  }
}

@media screen and (max-width: $tab-max) {

  .investors {

    &__item {
      width: 50%;
    }

  }

}

@media screen and (max-width: $tab-min) {

  .investors {
    padding-top: 60px;
    padding-bottom: 60px;

    &__item {
      width: 100%;
    }

  }

}






































