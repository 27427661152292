.creation {
  padding-top: 120px;
  padding-bottom: 120px;
  background-color: #fff;

  &__block {
    @include flex(row-reverse, space-between, center);
    margin-bottom: 120px;

    &:nth-child(odd) {
      flex-direction: row;

      .creation__block-info {
        padding-right: 20px;
        padding-left: 0;
      }
    }

    &:last-child {
      margin-bottom: 0;
    }

    &-info {
      width: 50%;
      padding-left: 20px;
    }

    &-video-wrap {
      //@include outg;
      width: 50%;
      max-width: 620px;
      //box-shadow: 0 0 30px 0 rgba(0,0,0,.2);
    }

    &-video {
      width: 100%;
      padding-top: 75%;
      position: relative;
      overflow: hidden;
      background-color: #d8d8d8;

      video {
        position: absolute;
        top: -100%;
        bottom: -100%;
        left: -100%;
        right: -100%;
        margin: auto;
        height: 100%;
      }

      img {
        width: 100%;
        display: none;
      }

      &-trigger {
        border: 1px solid red;
        position: absolute;
        top: 100%;
        left: 0;
        width: 100%;
      }

      .swc-video-btn_play {
        width: 60px;
        height: 60px;
        position: absolute;
        right: 50px;
        bottom: 50px;
        margin: auto;
        display: none;
      }
    }

    &-title {
      font: 32px/1.25 $rb;
      font-weight: bold;
      margin-bottom: 20px;
      color: $main-blue;
    }

    &-text {
      font: 24px/1.5 $rl;
    }
  }
}

@media screen and (max-width: $tab-max) {

  .creation {

    &__block {
      flex-direction: column;

      &:nth-child(odd) {
        flex-direction: column;

        .creation__block-info {
          width: 100%;
          padding: 0;
          max-width: 620px;
          margin-bottom: 20px;
        }

        .creation__block-video-wrap {
          width: 100%;
          max-width: 620px;
        }
      }

      &-info {
        width: 100%;
        max-width: 620px;
        padding: 0;
        margin-bottom: 20px;
      }

      &-video-wrap {
        width: 100%;
        max-width: 620px;
      }

      &-video {
        //width: 35%;
      }

    }

  }

}

@media screen and (max-width: $tab-min) {

  .creation {
    padding-top: 60px;
    padding-bottom: 60px;

    &__block {
      flex-direction: column;
      margin-bottom: 40px;

      &-info {
        width: 100%;
        margin-bottom: 20px;
        padding: 0;
      }

      &:nth-child(odd) {
        flex-direction: column;

        .creation__block-info {
          padding: 0;
        }
      }

      &-title {
        font: 26px/1.25 $rb;
        font-weight: bold;
      }

      &-text {
        font: 18px/1.5 $rl;
      }

      &-video-wrap {
        width: 100%;
        max-width: 620px;
      }

      &-video {
        //min-height: auto;

        video {
          //display: none;
        }

        img {
          display: block;
        }
      }

    }

    .swc-video-btn_play {
      display: block;
      right: 15px;
      bottom: 15px;
      //display: none!important;
    }

  }

}












































