.benefit {
  padding-top: 125px;
  padding-bottom: 70px;
  background-color: #fff;

  &__counts {
    @include flex(row, space-between, flex-start);
  }

  &__count {
    width: 33.33%;
    max-width: 400px;
    padding: 0 70px;
    text-align: center;

    &-span {
      font: 20px $rr;
      margin-bottom: 10px;

      &:last-child {
        margin-bottom: 0;
      }
    }

    &-number {
      font: 72px $rl;
      margin-bottom: 10px;
      color: $main-blue-light;
    }
  }

  &__bot-wrap {
    padding-top: 175px;
    background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0), #ffffff);
  }

  &__bot {
    padding-top: 385px;
    background: url('../img/bg/benefit.jpg') no-repeat top center;
    background-size: cover;
    margin-top: -40px;

    &-title {
      font: 64px $rb;
      font-weight: bold;
      text-align: center;
      margin-bottom: 80px;
      //text-shadow: 1px 1px 1px rgba(255,255,255,.4);
    }
  }

  &__items {
    @include flex(row, space-between, flex-start);
  }

  &__item {
    text-align: center;
    width: 33.33%;
    max-width: 400px;
    padding: 0 15px;

    &-img {
      margin-bottom: 20px;

      svg {
        width: 70px;
        height: 70px;
      }
    }

    &-title {
      font: 24px/28px $rb;
      font-weight: bold;
      margin-bottom: 20px;
      min-height: 56px;
    }

    &-text {
      font: 16px/1.5 $rr;
    }
  }
}

@media screen and (max-width: $tab-max) {

  .benefit {

    &__count {

      &-number {
        font: 40px $rl;
      }
    }
  }

}

@media screen and (max-width: $tab-min) {

  .benefit {
    padding-top: 60px;
    padding-bottom: 60px;

    &__counts {
      flex-wrap: wrap;
      text-align: center;
    }

    &__count {
      width: 50%;
      margin: 0 auto 50px;
      padding: 0px 10px;
    }

    &__bot {
      //margin-top: -240px;
      background: url('../img/bg/benefit_sm.jpg') no-repeat top center;
      background-size: contain;

      &-wrap {
        padding-top: 0;
      }

      &-title {
        font: 48px "Roboto Bold",sans-serif;
      }
    }

    &__items {
      flex-direction: column;
    }

    &__item {
      width: 100%;
      margin: auto;
      margin-bottom: 30px;
    }
  }

}


































