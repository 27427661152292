.banner {
  position: fixed;
  height: 100vh;
  //width: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  text-align: center;
  overflow: hidden;

  &__info {
    height: 100vh;
    border-top: 70px solid white;
    border-right: 140px solid white;
    border-left: 140px solid white;
    border-bottom: 70px solid white;
    position: relative;
    white-space: nowrap;
    padding: 30px;
    background-color: rgba(0, 48, 135, 0.5);

    &-arrow {
      display: block;
      content: "";
      position: fixed;
      left: 0;
      right: 0;
      bottom: 50px;
      margin: auto;
      width: 30px;
      height: 30px;
      border-bottom: 7px solid white;
      border-left: 7px solid white;
      transform: rotate(-45deg);
      //animation: arrowTopBottom 2s infinite ease;
    }

    //@keyframes arrowTopBottom {
    //  0%, 100% {
    //    bottom: 50px;
    //  }
    //  50% {
    //    bottom: 60px;
    //  }
    //}

    &:before {
      display: inline-block;
      vertical-align: middle;
      content: '';
      height: 100%;
      width: 0;
    }

    &-title {
      white-space: normal;
      //max-width: 840px;
      margin: auto;
      color: white;
      display: inline-block;
      vertical-align: middle;
      font: 64px/1.33 $rb;
      font-weight: bold;
      //text-shadow: 1px 1px 1px rgba(0, 0, 0, .4);
      //transform: translateY(50px);
      //opacity: 0;
      //animation: animate-banner-title 0.4s 0.05s forwards ease-out
    }
  }

  &_technology {
    //background-image: url('../img/bg/1.jpg');
    //background-size: cover;
    //background-position: center;
  }

  @-webkit-keyframes animate-banner-title {
    0% {
      opacity: 0;
      transform: translateY(50px)
    }

    100% {
      opacity: 1;
      transform: translateY(0px)
    }
  }

  video {
    position: absolute;
    top: -100%;
    bottom: -100%;
    left: -100%;
    right: -100%;
    margin: auto;
    min-height: 100%;
    min-width: 100%;
  }

  img {
    position: absolute;
    top: -100%;
    bottom: -100%;
    left: -100%;
    right: -100%;
    margin: auto;
    min-height: 100%;
    min-width: 100%;
  }

}

@media screen and (max-width: $tab-max) {

  .banner {

    &__info {
      border-top: 140px solid white;
      border-right: 70px solid white;
      border-left: 70px solid white;

      &-title {
        font-size: 6vw;
      }
    }

  }

}

@media screen and (max-width: $tab-min) {

  .banner {
    background: url(../video/tech/top_sm.jpg) no-repeat center;
    background-size: cover;

    &__info {
      border-top: 0 solid white!important;
      border-right: 0 solid white!important;
      border-left: 0 solid white!important;
      border-bottom: 0 solid white!important;
    }

    video {
      display: none;
    }

  }

}






































